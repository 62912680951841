


.openingCompanies {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fbfbfb; 
}

.personalInteraction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fbfbfb; 
  background-color: #fbfbfb; 
}

.personalInteraction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fbfbfb; 
}

.leftSection {
  flex: 1;
}
.rightSection {
  flex: 1;
}
.rightSection {
  flex: 1;
}

.videoContainer {

  flex: 1; 
  flex: 1; 
  position: relative;
  padding-top: 25%; 
  margin-left: 20px; 
  margin-right: 20px; 
}

.videoContainer iframe {
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0%;
}

.textContent {
  text-align: start;
  justify-items: start;
  display: grid;
  max-width: 600px;
  margin-left: 15%;
}

.textContent h1 {
  color: #428c9f;
  font-size: 50px;
  margin-bottom: 40px; 
  margin-bottom: 40px; 
}

.textContent span {
  color: black;
}

.textContent p {
  color: #555; 
  color: #555; 
  font-size: 1.2em;
  line-height: 1.5; 
  margin-bottom: 40px; 
  line-height: 1.5; 
  margin-bottom: 40px; 
}

.personalInteractionImage {
  width: 100%;
  height: auto;
  max-width: 500px;
}

.buttonOpeningSection {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  align-items: center;     /* Centers the buttons vertically if needed */
  gap: 20px;               /* Adds space between the buttons */
}

.buttonOpening {
  background-color: #428c9f; 
  color: white; 
  background-color: #428c9f; 
  color: white; 
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  border-radius: 15px;
}

@media (max-width: 1030px) {
  .openingCompanies {
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
  }

  .videoContainer {
    order: 2; 
    order: 2; 
    width: 80%;
    padding-top: 40%; 
    padding-top: 40%; 
    margin: 20px auto;
  }

  .leftSection {
    order: 1; 
    order: 1; 
    margin: 20px 0;
    text-align: center; 
  }
  
  .personalInteraction {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    text-align: center; 
  }
  
  .personalInteraction {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 660px) {
  .openingCompanies {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  

  .videoContainer {
    order: 2; 
    order: 2; 
    width: 100%;
    padding-top: 40%; 
    padding-top: 40%; 
    margin: 20px auto;
  }

  .leftSection {
    order: 1; 
    order: 1; 
    margin: 20px 0;
  }

  .personalInteractionImage {
    max-width: 80%;
    height: auto;
    margin: 20px auto;
  }
}
