/* src/styles/Company Dashboard styles/Companies Services styles/CompanyServicesTabs.module.css */

/* Botón de toggle para pantallas pequeñas */

.companyServicesDashboardContainer {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.toggleMenuButton {
  display: none; /* Oculto en pantallas grandes */
  background: none;
  border: none;
  font-size: 1.5em;
  color: #428c9f;
  cursor: pointer;
  margin: 0 auto 10px auto;
  align-items: center;
  justify-content: center;
}

/* Wrapper de las tabs para manejar el estado abierto/cerrado */
.tabsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Centrar las tabs horizontalmente */
  margin-bottom: 10px;
  border-bottom: 2px solid #ccc;
  gap: 5px;
}

/* Mostrar el botón de toggle y cambiar la disposición en pantallas pequeñas */
@media (max-width: 900px) {
  .toggleMenuButton {
    display: block; /* Mostrar en pantallas pequeñas */
  }

  .tabsWrapper {
    flex-direction: column;
    display: none; /* Ocultar por defecto */
    width: 100%;
    align-items: center; /* Centrar tabs en pantallas pequeñas también */
  }

  /* Mostrar las tabs cuando el menú está abierto */
  .tabsWrapper.tabsOpen {
    display: flex;
  }

  .companyServicesTabButton {
    width: 100%;
    text-align: left;
    padding: 10px 15px;
    border-top: 1px solid #ddd;
    font-size: 1.1em; /* Aumentar tamaño del texto en pantallas pequeñas */
  }

  .companyServicesTabButton:first-child {
    border-top: none;

  }
}

/* Estilos para las tabs */
.companyServicesTabButton {
  background-color: #fff;
  color: #428c9f;
  border: 1px solid #428c9f;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
  margin-right: 10px;
}

/* Hacer "Overview" más prominente */
.companyServicesTabButton:nth-child(1) {
  font-size: 1.2em; /* Aumentar tamaño del texto */
  font-weight: bold; /* Negrita */
  border: 2px dashed #428c9f;
}

/* Estilos al pasar el cursor y al enfocarse */
.companyServicesTabButton:hover,
.companyServicesTabButton:focus {
  background-color: #428c9f;
  color: #fff;
  outline: none;
}

/* Estilos para la tab activa */
.companyServicesActiveTab {
  background-color: #428c9f;
  color: #fff;
}

/* Contenedor de la tabla existente */
.companyServicesTableContainer {
  padding: 20px;
  background-color: #fff;
  overflow-x: auto;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* Tabla completa en CompanyServices */
.companyServicesTable {
  width: 70%;
  border-collapse: collapse;
  background-color: #fff;
}

/* Cabeceras de columna en CompanyServices */
.companyServicesHeader,
.companyServicesUserTypeHeader {
  background-color: #428c9f;
  color: #fff;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
  border-radius: 5px 5px 0 0;
  white-space: nowrap;
  font-size: 1.2em; /* Aumentado desde el tamaño original */
}

/* Celdas de datos en CompanyServices */
.companyServicesServiceName {
  padding: 15px; /* Aumentado de 12px a 15px para más espacio */
  text-align: left;
  color: #333;
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
  background-color: #fff;
  position: sticky;
  left: 0;
  z-index: 1;
  font-size: 1.1em; /* Aumentado desde el tamaño original */
}

.companyServicesHeader {
  position: sticky;
  left: 0;
  z-index: 1;
}

/* Iconos de chequeo en CompanyServices */
.companyServicesCheckIcon {
  text-align: center;
  font-size: 1.4em; /* Aumentado desde 1.2em */
  padding: 15px; /* Aumentado de 12px a 15px para más espacio */
  border-bottom: 1px solid #f0f0f0;
  color: #428c9f;
}

/* Celda vacía en CompanyServices */
.companyServicesEmptyCell {
  background-color: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  font-size: 1.1em; /* Aumentado para mayor legibilidad */
  color: #555555; /* Asegura buen contraste */
  padding: 15px; /* Aumentado de 12px a 15px */
}

/* Responsividad para pantallas pequeñas */
@media (max-width: 768px) {
  .companyServicesTable th,
  .companyServicesTable td {
    padding: 12px;
    font-size: 1em; /* Aumentado ligeramente */
  }

  /* Asegurar que la primera columna "Services" quede visible */
  .companyServicesServiceName {
    background-color: #fff;
    min-width: 150px; /* Asegura que la columna tenga un ancho mínimo */
    font-size: 1em; /* Aumentado ligeramente */
  }
}

/* Responsividad para pantallas medianas y grandes */
@media (min-width: 768px) {
  .companyServicesTable th,
  .companyServicesTable td {
    padding: 15px; /* Aumentado para pantallas medianas */
    font-size: 1.1em; /* Aumentado */
  }

  .companyServicesServiceName {
    font-size: 1.2em; /* Aumentado */
  }

  .companyServicesCheckIcon {
    font-size: 1.4em; /* Aumentado */
    padding: 15px; /* Aumentado */
  }

  .companyServicesEmptyCell {
    font-size: 1.1em; /* Aumentado */
    padding: 15px; /* Aumentado */
  }
}

@media (min-width: 1024px) {
  .companyServicesTable th,
  .companyServicesTable td {
    padding: 20px; /* Aumentado para pantallas grandes */
    font-size: 1.2em; /* Aumentado */
  }

  .companyServicesServiceName {
    font-size: 1.3em; /* Aumentado */
  }

  .companyServicesCheckIcon {
    font-size: 1.6em; /* Aumentado */
    padding: 15px; /* Mantiene el padding */
  }

  .companyServicesEmptyCell {
    font-size: 1.2em; /* Aumentado */
    padding: 15px; /* Mantiene el padding */
  }
}

/* Opcional: Estilos para el título y descripción del ServiceOverviewTab */
.companyServicesOverviewHeading {
  font-size: 2.5em; /* Aumentado para mejor visibilidad */
  text-align: center;
  color: #428c9f;
  margin-bottom: 20px;
}

.companyServicesOverviewDescription {
  font-size: 1.2em; /* Aumentado para mejor legibilidad */
  text-align: center;
  color: #555555;
  max-width: 800px;
  margin: 0 auto 40px auto;
}
