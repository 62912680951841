/* src/styles/Company Dashboard styles/Companies Services styles/RelocationToolTab.module.css */

.relocationServiceContainer {
    padding: 40px 20px; /* Reduced padding for smaller title */
    background-color: #fff; /* Light blue background */
    border-radius: 10px;
    max-width: 1400px; /* Increased max-width for larger screens */
    margin: 0 auto;
    color: #333333; /* Darker text for better contrast on light background */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  }
  
  .relocationServiceHeading {
    margin-top: 0;
    font-size: 2em; /* Reduced font size */
    margin-bottom: 10px; /* Reduced margin */
    color: #428c9f; /* Brand color for the heading */
    text-align: center;
  }
  
  .relocationServiceDescription {
    font-size: 1em; /* Reduced font size */
    margin-bottom: 30px; /* Reduced margin */
    color: #555555; /* Dark gray for readability */
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .relocationServiceAccordion {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .relocationServiceAccordionItem {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    background-color: #ffffff; /* Light background for accordion items */
  }
  
  .relocationServiceAccordionButton {
    width: 100%;
    background-color: #ffffff; /* Light background */
    color: #428c9f; /* Brand color for text */
    border: 2px solid #428c9f; /* Border with brand color */
    padding: 15px;
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .relocationServiceAccordionButton:hover,
  .relocationServiceAccordionButton:focus {
    background-color: #f1f1f1;
    border-color: #32718a; /* Darker shade on hover */
    outline: none;
  }
  
  .relocationServiceAccordionHeader {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .relocationServiceIconContainer {
    background-color: #428c9f; /* Brand color background for icons */
    border-radius: 50%;
    width: 60px; /* Increased size */
    height: 60px; /* Increased size */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .relocationServiceIcon {
    color: #ffffff; /* White icons for contrast */
    font-size: 2em; /* Increased icon size */
  }
  
  .relocationServiceAccordionTitle {
    font-size: 1.2em; /* Adjusted font size */
    font-weight: bold;
  }
  
  .relocationServiceAccordionIcon {
    font-size: 1.5em;
    transition: transform 0.3s;
  }
  
  .relocationServiceAccordionIcon.active {
    transform: rotate(180deg);
  }
  
  .relocationServiceAccordionContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    background-color: #f9f9f9; /* Slightly off-white background for content */
    padding: 0 15px;
    border-radius: 0 0 10px 10px;
  }
  
  .relocationServiceAccordionContent.show {
    max-height: 500px; /* Increased max-height for more content */
    padding: 15px;
  }
  
  .relocationServiceAccordionContent p {
    margin: 0 0 15px 0;
    color: #333333;
    font-size: 1em;
  }
  
  .relocationServiceCardVideo {
    width: 100%;
    height: 250px; /* Increased video height */
    border: none;
    border-radius: 10px;
  }
  
  /* Responsive Styling */
  
  /* Medium Screens */
  @media (min-width: 768px) {
    .relocationServiceContainer {
      padding: 40px 40px;
    }
  
    .relocationServiceHeading {
      font-size: 2.5em;
    }
  
    .relocationServiceDescription {
      font-size: 1.1em;
    }
  
    .relocationServiceAccordionButton {
      padding: 20px;
    }
  
    .relocationServiceIconContainer {
      width: 70px;
      height: 70px;
    }
  
    .relocationServiceIcon {
      font-size: 2.5em;
    }
  
    .relocationServiceAccordionContent.show {
      max-height: 600px;
      padding: 20px;
    }
  
    .relocationServiceCardVideo {
      height: 300px;
    }
  }
  
  /* Large Screens */
  @media (min-width: 1024px) {
    .relocationServiceContainer {
      padding: 40px 60px;
    }
  
    .relocationServiceHeading {
      font-size: 3em;
    }
  
    .relocationServiceDescription {
      font-size: 1.3em;
    }
  
    .relocationServiceAccordionButton {
      padding: 25px;
    }
  
    .relocationServiceIconContainer {
      width: 80px;
      height: 80px;
    }
  
    .relocationServiceIcon {
      font-size: 3em;
    }
  
    .relocationServiceAccordionContent.show {
      max-height: 700px;
      padding: 25px;
    }
  
    .relocationServiceCardVideo {
      height: 350px;
    }
  }
  