

.componentServices {
  padding: 20px 10px;
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  color: #333;
}

.componentServices h2 {
  color: #428c9f;
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.componentServices h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.componentServices h1 span {
  color: #428c9f;
}

.componentServices p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.containerServices {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}

.servicesList {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 0.4;
}

.serviceBox {
  background-color: #f9f9f9;
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.serviceBox:hover {
  background-color: #e4edf2;
  border-color: #428c9f;
}

.serviceBox.active {
  border-color: #428c9f;
  background-color: #d0e8f2;
}

.serviceContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.serviceContent h3 {
  margin: 0;
  font-size: 1.2rem;
}

.serviceContent h3 + .tooltipContainer {
  margin-left: 10px;
}

.lockIcon {
  color: #888;
  cursor: not-allowed;
}

.tooltipContainer {
  position: relative;
  display: inline-block;
  width: 190px;
}

.tooltipText {
  visibility: hidden;
  max-width: 90%;
  width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.9rem;
}

.tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.lockIcon {
  margin-left: 8px;
}

.serviceDetails {
  flex: 1;
  min-width: 200px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.serviceDetails h2 {
  color: #428c9f;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.serviceDetails p {
  font-size: 0.95rem;
  color: #555;
}

.serviceVideo {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.videoIframe {
  width: 75%;
  height: 250px;
  border: none;
  border-radius: 8px;
}

.serviceButton {
  text-align: center;
}

.linkButton {
  background-color: #428c9f;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 15px;
  margin: 4px 2px;
  margin-bottom: 20px;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s, transform 0.3s;
}

.linkButton:hover {
  background-color: #306b7a;
  transform: translateY(-2px);
}

@media (min-width: 1024px) {
  .serviceVideo {
    max-width: 800px;
    width: 80%;
    margin: 0 auto 20px;
  }

  .videoIframe {
    width: 80%;
    height: 300px;
  }

  .serviceDetails h2 {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .containerServices {
    flex-direction: column;
    align-items: stretch;
  }

  .servicesList {
    width: 100%;
  }

  .serviceDetails {
    width: 100%;
    margin-top: 10px;
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .serviceTitle {
    display: none;
  }

  .serviceDetails h2 {
    font-size: 1.3rem;
  }

  .serviceDetails p {
    font-size: 1rem;
  }

  .serviceVideo {
    height: auto;
    align-items: center;
  }

  .videoIframe {
    width: 80%;
    height: 200px;
  }

  .linkButton {
    width: auto;
    margin-top: 0px;
    margin-bottom: 20px;
    justify-content: center;
  }


  .tooltipText {
    max-width: 150px;
    font-size: 0.85rem;
    padding: 6px 8px;
  }

  .tooltipText::after {
    border-width: 4px;
  }
}

@media (max-width: 530px) {
  .componentServices h1 {
    font-size: 1.5rem;
  }
  .videoIframe {
    width: 110%;
    height: 220px;
  }
}
