/* InstructionsContainer.module.css */
  
.instructionsContainer {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 20px;
    border-radius: 15px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  }
  
  h2 {
    text-align: left;
    font-size: 30px;
    color: #428c9f;
    margin-bottom: 20px;
  }
  
  
  h3 {
    color: #428c9f;
  }
  
  /* InstructionsContainer.module.css */
  
  .watchDemoButton {
    margin-top: 10px;
    padding: 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    height: auto;
    width: auto;
    font-size: 1.5em;
  }
  
  .videoContainer {
    margin-top: 20px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .videoContainer {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    width: 80%;
    max-width: 800px;
    border: 1px solid #ddd;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  
  .videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 20%;
    height: 30%;
    border: 0;
  }
  
  /* LandingPage.module.css */
  
  .videoOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
  }
  
  .videoOverlay iframe {
    width: 80%;
    max-width: 800px;
    height: 50%;
    border: 1px solid #ddd;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  
  .instructionImage {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    margin-bottom: 20px;
  }  
  
  .instructionImageType {
    max-width: 100%;
    width: 70%;
    height: 60%;
    object-fit: cover; 
    margin-bottom: 20px;
  }  


  .instructionImageType {
    max-width: 100%;
    width: 70%;
    height: 60%;
    object-fit: cover; 
    margin-bottom: 20px;
  }  



  @media (max-width: 1150px) {
    .instructionsContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .instructionImage {
      width: 100%;
      height: auto;
      max-width: 100%; /* Ajusta el tamaño máximo */
      object-fit: cover; /* Ajusta el modo de ajuste de la imagen */
      margin-bottom: 20px;
      margin-left: 0;
    }
  }