.container {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 225px;
  background-color: #f5f5f5;
  padding: 20px;
  position: relative;
  overflow-y: auto;
  box-shadow: 3px 0 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.phaseItem {
  position: relative;
  color: #428c9f;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s;
  margin: 20px 0;
  z-index: 2;
}

.phaseItem:first-child {
  margin-top: 30px;
}

.phaseItem:last-child {
  margin-bottom: 30px;
}

.phaseItem:not(:last-child)::after {
  content: "";
  position: absolute;
  background-color: #428c9f;
  z-index: 1;
  margin-top: 10px;
  width: 2px;
  height: 20px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.phaseItem:hover .iconWrapper {
  transform: scale(1.2);
  z-index: 3;
}

.active {
  color: #428c9f;
}

.completed {
  color: #428c9f;
}

.locked {
  color: #428c9f;
  cursor: not-allowed;
  opacity: 0.8;
}

.iconWrapper {
  background-color: transparent;
  border: 2px solid #428c9f;
  color: #428c9f;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  transition: width 0.3s, height 0.3s, border-color 0.3s, transform 0.3s;
}

.phaseItem.active .iconWrapper {
  width: 110px;
  height: 110px;
  border-color: #428c9f;
}

.completedTopRightIcon {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 1.2rem;
  color: #28a745;
}

.lockedTopRightIcon {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 1.2rem;
  color: #95a5a6;
}

.phaseIcon.active {
  font-size: 1.8rem;
}

.phaseItem .phaseIcon {
  font-size: 1.6rem;
  transition: font-size 0.3s ease;
}

.phaseItem.active .phaseIcon {
  font-size: 2rem;
}

.phaseItem.active .iconWrapper {
  background-color: #428c9f;
  color: white;
}

.phaseName {
  font-size: 14px;
  text-align: center;
}

.content {

  flex: 1;
  padding: 40px;
  overflow-y: auto;
}

.phaseContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.phaseContent h2 {
  margin-top: 0;
  color: #2c3e50;
}

.phaseContent p {
  color: #7f8c8d;
  text-align: justify;
}

.loading,
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5em;
  color: #2c3e50;
}

@keyframes glowingPulse {
  0% {
    box-shadow: 0 0 5px rgba(66, 140, 159, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(66, 140, 159, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(66, 140, 159, 0.5);
  }
}

@keyframes float {
  0% {
    transform: translateY(-50%) translateY(0);
  }
  50% {
    transform: translateY(-50%) translateY(-10px);
  }
  100% {
    transform: translateY(-50%) translateY(0);
  }
}

.logoActive {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20%;
  right: -35px;
  transform: translateY(-50%);
  animation: glowingPulse 3s infinite;
  box-shadow: 0 0 10px rgba(66, 140, 159, 0.6);
  border-radius: 50%;
}

.floatingLogo {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 60%;
  right: -30px;
  transform: translateY(-50%);
  animation: float 4s ease-in-out infinite;
  box-shadow: 0 0 5px rgba(66, 140, 159, 0.5);
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .sidebar {
    width: 200px;
  }

  .iconWrapper {
    width: 80px;
    height: 80px;
  }

  .phaseItem.active .iconWrapper {
    width: 100px;
    height: 100px;
  }

  .logoActive,
  .floatingLogo {
    width: 25px;
    height: 25px;
    right: -30px;
  }
}

@media (max-width: 992px) {
  .sidebar {
    width: 180px;
    padding: 15px;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  }

  .iconWrapper {
    width: 70px;
    height: 70px;
  }

  .phaseItem.active .iconWrapper {
    width: 90px;
    height: 90px;
  }

  .logoActive,
  .floatingLogo {
    width: 20px;
    height: 20px;
    right: -25px;
  }

  .phaseName {
    font-size: 13px;
  }

  .content {
    padding: 30px;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    box-shadow: none;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    height: 150px;
  }

  .phaseItem {
    margin: 0 10px;
  }

  .phaseItem:first-child {
    margin-top: 0;
  }
  .phaseItem:last-child {
    margin-bottom: 0;
  }

  .iconWrapper {
    width: 60px;
    height: 60px;
  }

  .phaseItem.active .iconWrapper {
    width: 80px;
    height: 80px;
    font-size: 1.4em;
  }

  .phaseName {
    font-size: 11px;
  }

  .logoActive,
  .floatingLogo {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 24%;
    left: 80%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  .content {
    padding: 20px;

  }
  .phaseItem:not(:last-child)::after {
    width: 20px;
    height: 2px;
    top: 50%;
    margin-top: 0;
    margin-left: 2px;
    left: 100%;
    transform: translateY(-50%);
  }

  
}

@media (max-width: 576px) {
  .phaseName {
    font-size: 10px;
  }

  .logoActive,
  .floatingLogo {
    width: 15px;
    height: 15px;
    right: -20px;
  }

  .content {
    padding: 15px;
  }

  .sidebar {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .phaseItem {
    margin: 0 10px;
  }
  .phaseItem:not(:last-child)::after {
    width: 20px;
    height: 2px;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
  }
}
