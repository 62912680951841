.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border: none;
  background-color: transparent;
}

.form h2 {
  text-align: left;
  color: #428c9f;
  padding-left: 10px;
}

.optionsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.option {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.option input {
  appearance: none;
  background-color: #f9f9f9;
  width: 1.15em;
  height: 1.15em;
  border: 2px solid #cacece;
  display: grid;
  place-content: center;
}

.option input[type="checkbox"]:checked::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: circle(40%);
  background-color: #428c9f;
  transform: scale(1.2);
}

.option label {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  gap: 20px;
}

.icon {
  font-size: 25px;
  color: #428c9f;
}

@media (max-width: 768px) {
  .optionsContainer {
    grid-template-columns: 1fr;
  }
}
