/* src/styles/Company Dashboard styles/Home.module.css */

.homeContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .sectionTitle {
    font-size: 1.5em;
    color: #428c9f;
    border-bottom: 2px solid #ddd;
    padding-bottom: 8px;
    margin-bottom: 15px;
  }
  
  .recentActivity, .keyMetrics, .importantNotifications, .quickAccess, .newsResources {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
  }
  
  .activityList, .notificationList, .newsList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .activityItem, .notificationItem, .newsItem {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .activityItem:last-child, .notificationItem:last-child, .newsItem:last-child {
    border-bottom: none;
  }
  
  .activityIcon, .notificationIcon {
    margin-right: 10px;
    font-size: 1.2em;
  }
  
  .keyMetrics {
    display: flex;
    flex-direction: column;
  }
  
  .metricsContainer {
    display: flex;
    gap: 15px;
  }
  
  .metricCard {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e3f2fd;
    border-radius: 8px;
    padding: 15px;
    color: #428c9f;
    font-weight: bold;
  }
  
  .metricValue {
    font-size: 1.8em;
  }
  
  .metricLabel {
    font-size: 0.9em;
  }
  
  .quickActions {
    display: flex;
    gap: 15px;
  }
  
  .quickButton {
    flex: 1;
    padding: 12px;
    background-color: #428c9f;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
  }
  
  .quickButton:hover {
    background-color: #32718b;
  }
  