.interactiveMapCloropleth__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
}

.interactiveMapCloropleth__container h2 {
  font-weight: bold;
  color: #428c9f;
  margin: 10px;
  text-align: center;
}

.interactiveMapCloropleth__container p {
  color: #444;
  text-align: center;
  max-width: 800px;
}

.interactiveMapCloropleth__content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  width: 90%;
}

.interactiveMapCloropleth__mapBox {
  max-width: 800px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 4;
  position: relative;
}

.interactiveMapCloropleth__tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
  gap: 5px;
}

.interactiveMapCloropleth__tabs button {
  padding: 5px 10px;
  font-size: 18px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s;
}

.interactiveMapCloropleth__tabs button:hover {
  background-color: #a2d9e3;
}

.interactiveMapCloropleth__tabs .interactiveMapCloropleth__activeTab {
  background-color: #428c9f;
  color: #fff;
  border-color: #428c9f;
}

.interactiveMapCloropleth__mapWrapper {
  position: relative;
  width: 100%;
}

.interactiveMapCloropleth__mapSvg {
  width: 100%;
}

.interactiveMapCloropleth__tooltip {
  position: absolute;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 10px;
  z-index: 10;
}

.interactiveMapCloropleth__legend {
  margin-top: 10px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 150px;
  width: 150px;
}

.interactiveMapCloropleth__legend .interactiveMapCloropleth__gradient {
  height: 15px;
  background: linear-gradient(to right, #a2d9e3, #428c9f);
  border: 1px solid #ddd;
}

.interactiveMapCloropleth__legend .interactiveMapCloropleth__labels {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.interactiveMapCloropleth__infoBox {
  flex: 0.5;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-self: center;
}

.interactiveMapCloropleth__infoBox h3 {
  margin: 0 0 10px;
  font-size: 24px;
  color: #428c9f;
}

.interactiveMapCloropleth__infoBox p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.interactiveMapCloropleth__infoBox ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.interactiveMapCloropleth__infoBox li {
  font-size: 1rem;
  line-height: 1.5;
  align-items: left;

}

.interactiveMapCloropleth__description {
  margin-top: 15px;
}

.interactiveMapCloropleth__description h4 {
  margin: 0 0 10px;
  font-size: 16px;
  color: #555;
}

.interactiveMapCloropleth__description p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

@media screen and (max-width: 768px) {
  .interactiveMapCloropleth__container {
    flex-direction: column;
    padding: 10px;
  }

  .interactiveMapCloropleth__container h2 {
    font-size: 20px;
    margin: 10px 0;
  }

  .interactiveMapCloropleth__container p {
    font-size: 14px;
    padding: 0 10px;
    max-width: 100%;
  }

  .interactiveMapCloropleth__content {
    flex-direction: column;
    gap: 15px;
    width: auto;
  }

  .interactiveMapCloropleth__mapBox {
    flex: unset;
    width: 100%;
    padding: 10px;
  }

  .interactiveMapCloropleth__infoBox {
    flex: unset;
    width: 100%;
    padding: 10px;
  }

  .interactiveMapCloropleth__tabs {
    justify-content: center;
  }

  .interactiveMapCloropleth__tabs button {
    font-size: 14px;
    padding: 5px 8px;
  }

  .interactiveMapCloropleth__mapWrapper {
    width: 100%;
    height: auto;
  }

  .interactiveMapCloropleth__legend {
    position: relative;
    bottom: unset;
    left: unset;
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }

  .interactiveMapCloropleth__legend .interactiveMapCloropleth__gradient {
    margin: 0 auto;
  }

  .interactiveMapCloropleth__legend .interactiveMapCloropleth__labels {
    font-size: 13px;
  }

  .interactiveMapCloropleth__infoBox h3 {
    font-size: 16px;
  }

  .interactiveMapCloropleth__infoBox p {
    font-size: 14px;
  }

  .interactiveMapCloropleth__description h4 {
    font-size: 14px;
  }

  .interactiveMapCloropleth__description p {
    font-size: 12px;
  }
}

.interactiveMapCloropleth__tooltip {
  position: absolute;
  background: white;
  padding: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
}

.interactiveMapCloropleth__selectedLegend {
  margin-top: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
}
