

.whatIsIncluded {
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto; 
}

.includedHeader {
  font-size: 2rem;
  color: #428c9f;
  margin-bottom: 20px;
}

.includedList {
  list-style-type: disc;
  padding-left: 40px; 
  margin-bottom: 30px;
}

.includedListItem {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
  line-height: 1.6;
}

.includedImageContainer {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  align-items: center;
  gap: 20px; 
  margin-top: 30px; 
}

.includedImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.includedImage:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}


.includedImageSmall {
  width: 45%; 
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.includedImageSmall:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.includedContact {
  font-size: 1rem;
  color: #333;
  text-align: center;
  margin-top: 20px;
}

.includedContact a {
  color: #428c9f;
  text-decoration: none;
  font-weight: bold;
}

.includedContact a:hover {
  text-decoration: underline;
}


@media (max-width: 768px) {
  .whatIsIncluded {
    padding: 20px 15px;
    margin: 20px auto;
    max-width: 100%;
  }

  .includedHeader {
    font-size: 1.5rem;
  }

  .includedList {
    padding-left: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .includedListItem {
    font-size: 0.95rem;
  }

  .includedImageContainer {
    gap: 15px;
    margin-top: 20px;
  }

  .includedImage {
    width: 100%;
    height: auto;
  }

  .includedImageSmall {
    width: 100%; 
  }
}





/* src/styles/Company Dashboard styles/CompanyServices styles/CompanyServicesTable.module.css */

.companyServicesContainer {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 0 auto;
}

.companyServicesHeading {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
  text-align: left;
}

.servicesTable {
  display: grid;
  grid-template-columns: 1fr repeat(3, minmax(150px, 1fr));
  gap: 10px;
  align-items: center;
}

.tableHeader {
  display: contents;
  font-weight: bold;
  color: #428c9f;
  border-bottom: 2px solid #ddd;
}

.userCategory {
  text-align: center;
  padding: 10px 0;
  font-size: 1.1em;
}

.tableRow {
  display: contents;
  border-bottom: 1px solid #ddd;
}

.serviceColumn {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px 10px;
}

.serviceIcon {
  font-size: 2em;
  color: #428c9f;
}

.serviceText {
  display: flex;
  flex-direction: column;
}

.serviceText strong {
  font-size: 1.1em;
  color: #333;
}

.serviceText p {
  font-size: 0.9em;
  color: #777;
  margin: 5px 0 0;
}

.userCategory > .FcCheckmark {
  font-size: 1.5em;
  color: #428c9f;
}

.userCategory span {
  font-size: 0.9em;
  color: #555;
}
