/* src/styles/Profile styles/CompanyProfile.module.css */

/* ... código existente ... */

.companyProfileContainer {
  color: #333;
  padding: 20px;
  max-width: 900px; /* Aumentado para mayor espacio */
  margin: 0 auto;
  background-color: #ffffff; /* Fondo blanco más limpio */
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 25px; /* Mayor separación entre secciones */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para profundidad */
  border-radius: 10px; /* Bordes redondeados */
}

.companyProfileBasicProfile {
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #428c9f;
}

.companyProfileLogoPlaceholder {
  max-width: 100px;
  max-height: 100px;
  border: 4px solid #428c9f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  font-weight: bold;
  font-size: 18px;
  overflow: hidden;
  background-color: #f0f4f8; /* Fondo tenue para mejor contraste */
}

.companyProfileLogoImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.companyProfileCompanyName {
  font-size: 2em;
  margin: 10px 0 5px 0;
  color: #428c9f;
}

.companyProfileSlogan {
  font-style: italic;
  color: #555;
  margin-bottom: 5px;
}

.companyProfileLocation {
  color: #777;
  font-size: 1.1em;
}

/* Tabs */
.tabsContainer {
  display: flex;
  gap: 15px;
  margin-bottom: 25px;
  border-bottom: 2px solid #ccc;
  justify-content: center; /* Centrado de pestañas */
}

.tabButton {
  padding: 12px 25px;
  background-color: #f0f4f8;
  border: none;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  font-size: 16px;
  transition: border-bottom 0.3s, background-color 0.3s;
  border-radius: 5px 5px 0 0;
  position: relative;
}

.tabButton:hover {
  background-color: #e0e7ef;
}

.activeTab {
  border-bottom: 4px solid #428c9f;
  font-weight: bold;
  background-color: #e0e7ef;
}

/* Contenido de las pestañas */
.companyProfileTabContent {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

/* Grid para Company Details */
.detailsGrid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 15px 30px;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 25px;
}

.detailItem {
  display: contents;
}

.detailLabel {
  font-weight: bold;
  color: #555;
  text-align: right;
  padding-right: 15px;
  border-bottom: 1px solid #ddd;
  position: relative;
}

/* Tooltip */
.detailLabel::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #428c9f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

.detailLabel:hover::after {
  opacity: 1;
}

.detailValue {
  color: #333;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.detailInput {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  transition: border-color 0.3s;
}

.detailInput:focus {
  border-color: #428c9f;
  outline: none;
}

/* Botones */
.editButtonsContainer {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  justify-content: flex-end; /* Alinear botones a la derecha */
}

.companyProfileEditButton,
.companyProfileSaveButton,
.companyProfileCancelButton {
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.companyProfileEditButton {
  background-color: #428c9f; /* Azul para Edit */
}

.companyProfileEditButton:hover {
  background-color: #32718b;
}

.companyProfileSaveButton {
  background-color: #428c9f; /* Verde para Save */
}

.companyProfileSaveButton:hover {
  background-color: #32718b;
}

.companyProfileCancelButton {
  background-color: #ddd; /* Rojo para Cancel */
}

.companyProfileCancelButton:hover {
  background-color: #d5d5d5;
}

.companyProfileLink {
  color: #428c9f;
  text-decoration: none;
  border: 1px solid #ddd;
  padding: 6px 12px;
  border-radius: 6px;
  transition: background-color 0.3s;
}

.companyProfileLink:hover {
  background-color: #f0f4f8;
}

.companyProfileLoading,
.companyProfileError,
.companyProfileNoData {
  text-align: center;
  font-size: 1.2em;
  color: #428c9f;
}

.companyProfileError {
  color: red;
}

.companyProfileNoData {
  color: #777;
}

/* Placeholder para contenido adicional */
.companyProfilePlaceholder {
  color: #777;
  font-style: italic;
}

/* Nuevo Estilo para Modo Edición del Logo */
.companyProfileLogoUpdate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
}

.companyProfileLogoUpdate input {
}

/* Estilos para Notifications */
.companyProfileNotifications {
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.notificationSettings {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.notificationItem {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
  color: #333;
  position: relative;
}

/* Tooltip */
.notificationItem::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #428c9f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

.notificationItem:hover::after {
  opacity: 1;
}

.notificationFrequency {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.notificationFrequency label {
  font-weight: bold;
  color: #555;
}

.notificationFrequency select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: border-color 0.3s;
}

.notificationFrequency select:focus {
  border-color: #428c9f;
  outline: none;
}

/* Estilos para Change Password */
.companyProfileChangePassword {
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.changePasswordForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.formGroup {
  position: relative; /* Para posicionar el botón del ojo */
}

.label {
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
  display: block;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.input {
  padding: 10px 40px 10px 10px; /* Espacio para el botón del ojo */
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  transition: border-color 0.3s;
  margin-bottom: 10px;
}

.input:focus {
  border-color: #428c9f;
  outline: none;
}

.eyeButton {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #555;
  font-size: 1.2em;
  padding: 0;
}

.changePasswordButton {
  padding: 12px 20px;
  background-color: #428c9f; /* Azul personalizado */
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.changePasswordButton:hover {
  background-color: #32718b;
}

.error {
  color: #ddd;
  font-size: 14px;
}

.success {
  color: #428c9f;
  font-size: 14px;
}

/* Password Validation Component */
.passwordValidation {
  font-size: 14px;
  color: #555;
}

/* Responsividad para botones y contenido */
@media (max-width: 768px) {
  .companyProfileContainer {
    padding: 15px;
  }

  .tabsContainer {
    flex-direction: column;
    align-items: center;
  }

  .tabButton {
    padding: 10px 15px;
    font-size: 14px;
  }

  .companyProfileEditButton,
  .companyProfileSaveButton,
  .companyProfileCancelButton,
  .changePasswordButton {
    padding: 8px 15px;
    font-size: 14px;
  }

  .detailsGrid {
    grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
  }

  .inputWrapper {
    .inputWrapper {
      flex-direction: row; /* Alinear input y botón del ojo en fila */
      align-items: center;
    }
  
    .eyeButton {
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }top: 5px;
  }
}
