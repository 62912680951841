.candidateCustomer {
    max-width: 900px;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .notifications {
    position: fixed;
    top: 100px;
    right: 20px;
    z-index: 1000;
  }
  .buttonContainer {
    margin-bottom: 16px; /* Espaciado entre botones */
    display: flex;
    justify-content: center; /* Centrar botones horizontalmente */
  }
  
  .notification {
    background-color: #428c9f;
    color: white;
    padding: 12px 20px;
    border-radius: 6px;
    margin-bottom: 10px;
    font-size: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  
  .notificationImage {
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  @keyframes fadein {
    from { opacity: 0; right: 0; }
    to { opacity: 1; right: 20px; }
  }
  
  @keyframes fadeout {
    from { opacity: 1; right: 20px; }
    to { opacity: 0; right: 0; }
  }
  
  .header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .headerBox {
    background-color: #f0f8ff;
    padding: 20px;
    border-radius: 10px;
    display: inline-block;
  }
  
  .avatar {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 15px;
    border: 2px solid #428c9f;
  }
  
  .title {
    font-size: 26px;
    font-weight: bold;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #428c9f;
  }
  
  .titleIcon {
    margin-right: 10px;
    font-size: 28px;
  }
  
  .motivationalMessage {
    font-size: 18px;
    color: #555;
    max-width: 100%;
  }
  
  .progressTracker {
    margin-bottom: 30px;
  }
  
  .sectionTitle {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #428c9f;
  }
  
  .progressBarContainer {
    position: relative;
    height: 25px;
    background-color: #e0e0e0;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 15px;
  }
  
  .progressBar {
    background-color: #ddd;
    height: 100%;
    border-radius: 12px;
    border: 2px solid #428c9f;
  }
  
  .progress {
    background-color: #428c9f;
    height: 100%;
    transition: width 0.5s ease;
  }

  .progressLabel {
    font-weight: bold;
    font-size: 16px;
    color: #333;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .prominentButton {
    background: #428c9f;
    color: white;
    border: none;
    padding: 14px 28px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.3s ease;
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .prominentButton:hover {
    background:  #a1d9e8; /* Invertir degradado en hover */
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .prominentButton:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(255, 126, 95, 0.5); /* Resaltar en foco */
  }
  
  .prominentButton:active {
    transform: translateY(0);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .prominentButton:disabled {
    background: #ccc;
    cursor: not-allowed;
    box-shadow: none;
  }
  
  .actionIcon {
    margin-right: 8px;
    font-size: 20px;
  }
  
  .badges {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    margin-top: 10px;
  }
  
  .badge {
    background-color:#28a745; /* Degradado llamativo */
    color: white;
    padding: 6px 14px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .stepsSection {
    margin-top: 30px;
  }
  
  .gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  @media (min-width: 768px) {
    .gridContainer {
      grid-template-columns: 1fr;
    }
  }
  
  .stepCard {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 30px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .stepCard:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-3px);
  }
  
  .stepHeader {
    display: flex;
    align-items: center;
  }
  
  .checkButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkButton:focus {
    outline: none;
  }
  
  .checkIcon {
    color: #28a745;
    font-size: 24px;
    transition: color 0.3s ease;
  }
  
  .uncheckedIcon {
    color: #ccc;
    font-size: 24px;
    transition: color 0.3s ease;
  }
  
  .checkButton:hover .checkIcon,
  .checkButton:hover .uncheckedIcon {
    color: #428c9f;
  }
  
  .leftHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .leftHeader:focus {
    outline: none;
  }
  
  .stepTitle {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: #428c9f;
  }
  
  .icon {
    margin-right: 8px;
    font-size: 20px;
  }
  
  .rightHeader {
    margin-left: auto;
  }
  
  .toggleButton {
    background: none;
    border: none;
    color: #428c9f;
    cursor: pointer;
    font-size: 18px;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 4px;
  }
  
  .toggleButton:hover {
    color: #2a6f8c;
    background-color: rgba(66, 140, 159, 0.1);
  }
  
  .stepDetails {
    margin-top: 15px;
    border-top: 1px solid #eee;
    padding-top: 15px;
  }
  
  .completed {
    display: flex;
    align-items: center;
    color: #428c9f;
  }
  
  .completed .checkIcon {
    margin-right: 10px;
  }
  
  .stepDetails p {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .actionsList {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .actionsList li {
    font-size: 14px;
    color: #999;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    border-radius: 6px;
    transition: background-color 0.3s ease;
  }
  
  .actionsList li:hover {
  }
  
  .listIcon {
    margin-right: 8px;
    color: #428c9f;
  }
  
  .actionLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #428c9f;
    font-weight: 500;
  }
  
  .actionLink:hover {
    text-decoration: underline;
  }
  
  /* Las clases para las secciones expandibles permanecen iguales */
  
  .sampleQuestionsSection {
    margin-top: 15px;
    margin-bottom: 20px;
    justify-items: center;
  }
  
  .sampleQuestionsToggle {
    background: none;
    border: none;
    color: #428c9f;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0;
    transition: color 0.3s ease;
    margin-bottom: 10px;
  }
  
  .sampleQuestionsToggle:hover {
    text-decoration: underline;
    color: #2a6f8c;
  }
  
  .sampleQuestionsList {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #555;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .sampleQuestionsList li {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding-left: 25px;
  }
  
  .sampleQuestionsList li::before {
    content: "❓";
    position: absolute;
    left: 0;
    top: 0;
    font-size: 18px;
    color: #428c9f;
    margin-top: 2px;
  }
  
  .sampleQuestionsList li:hover {
    color: #333;
  }
  
  .sampleVideoTopicsSection {
    margin-top: 15px;
    justify-items: center;
  }
  
  .sampleVideoTopicsToggle {
    background: none;
    border: none;
    color: #428c9f;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0;
    transition: color 0.3s ease;
    margin-bottom: 20px;
}

.sampleVideoTopicsToggle:hover {
    text-decoration: underline;
    color: #2a6f8c;
}

.sampleVideoTopicsList {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #555;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .sampleVideoTopicsList li {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding-left: 25px;
  }
  
  .sampleVideoTopicsList li::before {
    content: "❓";
    position: absolute;
    left: 0;
    top: 0;
    font-size: 18px;
    color: #428c9f;
    margin-top: 2px;
  }
  
  .sampleVideoTopicsList li:hover {
    color: #333;
  }
  
  .bookCallSection {
    margin-top: 15px;
    justify-items: center;
  }
  
  .bookCallToggle {
    background: none;
    border: none;
    color: #428c9f;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 0;
    transition: color 0.3s ease;
  }
  
  .bookCallToggle:hover {
    text-decoration: underline;
    color: #2a6f8c;
  }
  
  .bookCallInstructions {
    margin-top: 10px;
    color: #555;
    font-size: 14px;
    margin-bottom: 20px;
  }
  