/* ContactUs.module.css */
.contactUsLayout {
  background-color: #fbfbfb;
  margin-top: 50px;
  display: flex;
  justify-content: space-between; /* Distribuye el espacio entre las secciones */
  align-items: flex-start; /* Alinea los elementos al inicio */
  gap: 20px; /* Espacio entre las secciones */
  flex-wrap: wrap; /* Permite que las secciones se apilen verticalmente en pantallas pequeñas */
}

.contactText {
  flex: 1; /* Hace que ambas secciones ocupen espacio disponible de manera equitativa */
  padding: 20px;
  margin-top: 40px; /* Espacio uniforme desde el top */
  
}

.contactFormContainer {
  flex: 0.8; /* Hace que ambas secciones ocupen espacio disponible de manera equitativa */
  padding: 20px;
  background-color: #fbfbfb;
  margin-bottom: 60px; /* Espacio uniforme desde el top */

}
.contactUsContainer {
  flex: 0.8;
  padding: 20px;
  margin-top: 40px;
  background-color: #428c9f;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 0%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  position: relative;
}




.contactText {
margin-left: 15%;  
text-align: start;
justify-content: flex-start;
align-items: center;

}

.contactText h2 {
  font-size: 1.5em;
  margin-bottom: 40px;
  
}
.contactText h1 {
  font-size: 2.5em;
  margin-bottom: 40px;
  


}
.contactText p {
    color: #555; 
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 40px;
}


.contactUsContainer h1 {
  margin-left: auto;
  margin-right: auto;
  max-width: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #428c9f;

}

.contactUsContainer p {
  margin-bottom: 20px;
  margin-top: 20px;
  color: #666;
}

.formGroup {
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
}

.formGroup label {
  display: block;
  margin-bottom: 15px;
}

.formGroup input {
  width: 60%;
  padding: 8px;
  border: 1px solid black;
  border-radius: 4px;
  margin-bottom: 10px;
  height: auto;
}

.formGroup textarea {
  width: 60%;
  margin-left: px;
  margin-right: 0px;
  padding: 8px;
  border: 1px solid black;
  border-radius: 4px;
  margin-bottom: 10px;
  height: 100px; /* Ajusta la altura según tus necesidades */
}


.submitButton {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  background-color: black; /* Color de fondo suave */
  color: white; /* Texto en negro para contraste */
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer; /* Cursor de mano al pasar el ratón */
  display: flex;
  align-items: center;
  justify-content: center; /* Centra los elementos horizontalmente */
  gap: 8px; /* Espacio entre icono y texto en whatsappLink */
  text-decoration: none; /* No subrayado en el enlace */
  font-size: 1.2em;
}

.submitButton .icon {
  font-size: 1.2rem; /* Ajusta el tamaño del icono según sea necesario */
}


/* ContactUs.module.css */
.whatsappLink {
  display: inline-flex; /* Muestra el ícono y el texto en línea */
  align-items: center; /* Alinea verticalmente el ícono con el texto */
  color: black; /* Color oficial de WhatsApp */
  text-decoration: none; /* Elimina el subrayado del enlace */
  font-size: 18px; /* Tamaño de fuente del texto */
  gap: 8px; /* Espacio entre el ícono y el texto */
}
.whatsappLink:hover {
  text-decoration: underline; /* Subraya el enlace al pasar el mouse */
}
.whatsappIcon {
  color: #428c9f; /* Azul para el icono */
}
  
.charCounter {
  font-size: 12px;
  text-align: center;
  color: black;
}

.highlighted {
  color: #428c9f;
  font-weight: bold;
}


@media (max-width: 1100px) {
  .contactUsLayout {
    flex-direction: column; /* Apila los contenedores en pantallas pequeñas */
  }

  .contactUsContainer,
  .contactText,
  .contactFormContainer {
    width: 80%;
    margin: 20px auto;
    margin-top: 20px;
  }
  .contactText p {
    
    margin-bottom: 0px;
}
}


.logoContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

img .logo {
  width: 5px; /* Ajusta el ancho según tus necesidades */
  height: 5px; /* Mantiene la proporción original */
  margin-left: 10px; /* Espaciado entre el título y el logo */
  /* Otros estilos según sea necesario */
  justify-content: flex-end;

}

