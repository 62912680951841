/* src/styles/VideoLibrary styles/VideoLibrary.module.css */

.videoLibraryContainer {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  color: #333;
}

.videoLibraryContainer h2 {
  color: #428c9f;
  font-size: 1.5rem;
  margin-bottom: 12px;
}

.videoLibraryContainer h1 {
  font-size: 2rem;
  margin-bottom: 16px;
}

.videoLibraryContainer p {
  font-size: 0.9rem;
  margin-bottom: 40px;
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.filterButton {
  background-color: #f0f0f0;
  border: none;
  color: #428c9f;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
}

.filterButton:hover,
.activeFilter {
  background-color: #428c9f;
  color: white;
}

/* Carrusel */
.carouselContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.carousel {
  width: 100%;
}

.carouselWrapper {
  overflow: hidden;
  width: 100%;
}

.carouselTrack {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.videoCard {
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 10px;
  max-width: 100%;
  /* Limitar el ancho máximo para evitar videos enormes */
  max-width: 400px;
  margin: 0 auto;
}

.videoCard.active,
.videoCard:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.videoIframe {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}

.videoTitle {
  margin-top: 10px;
  font-size: 1.1rem;
  color: #428c9f;
  min-height: 50px;
}

/* Botones de Navegación */
.navButtonsContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.navButton {
  background-color: rgba(66, 140, 159, 0.8);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s, transform 0.3s;
}

.navButton:hover {
  background-color: rgba(66, 140, 159, 1);
  transform: scale(1.1);
}

/* Indicadores de posición */
.carouselIndicators {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 10px;
}

.carouselIndicator {
  width: 10px;
  height: 10px;
  background-color: #ddd;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.carouselIndicator.activeIndicator {
  background-color: #428c9f;
}

/* Contador de videos */
.carouselCounter {
  text-align: center;
  font-size: 1rem;
  color: #428c9f;
}

/* Ajustes Responsivos Mejorados */

/* Pantallas pequeñas */
@media (max-width: 600px) {
  .videoIframe {
    height: 180px;
  }

  .videoLibraryContainer h1 {
    font-size: 1.5rem;
  }

  .videoLibraryContainer h2 {
    font-size: 1.3rem;
  }

  .filterButton {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .videoTitle {
    font-size: 1rem;
  }

  .navButton {
    padding: 8px;
  }

  .carouselIndicator {
    width: 8px;
    height: 8px;
  }
}
