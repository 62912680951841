.card {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-top: 50px;
  }
  
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #428c9f;
    margin-bottom: 20px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  
  .message {
    text-align: center;
    color: #4b5563;
  }

  .validationImage {
    width: 100%;

  }
  
  .button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #428c9f;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #d6e4ec;
  }