/* LandingSection.module.css */


.landingSection {
  display: flex;
  justify-content: space-between;
}

.contentContainer {
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 50px;
}

.textContent {
  flex: 1;
  text-align: start;
  justify-items: start;
  display: grid;
  margin-left: 100px;
}

.textContent h1 {
  color: black;
  font-size: 50px;
  margin-bottom: 40px;
}

.textContent p {
  color: #555;
  font-size: 1.2em;
  line-height: 1.5;
  margin-bottom: 40px;
}

.textContent span {
  color: #428c9f;
}

.textContentParagraph span {
  color: black;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.primaryButton,
.secondaryButton {
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  font-size: 1.5em;
  cursor: pointer;
}

.primaryButton {
  background-color: #428c9f;
  color: #fff;
}

.secondaryButton {
  background-color: #428c9f;
  color: #fff;
}

.demoContainer {
  margin-left: 10%;
  margin-right: 5%;
  margin-bottom: 70px; 
}

@media (max-width: 900px) {
  .landingSection {
    flex-direction: column-reverse;
    align-items: center;
    padding: 20px; 
  }

  .textContent {
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
  }

  .demoContainer {
    margin-left: 10%;
    margin-right: 10%;
  }
}
