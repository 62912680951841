/* src/styles/Dashboard styles/CreateLinks.module.css */

.section {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto;
  background-color: #f9f9f9;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.linkList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.linkItem {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #fff;
}

.userTypeInfo {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  margin-right: 8px;
  font-size: 1.5rem;
  color: #428c9f;
}

.userTypeName {
  font-size: 1.2rem;
  color: #333;
}

.linkContainer {
  display: flex;
  align-items: center;
}

.linkInput {
  flex: 1;
  padding: 8px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow-x: auto;
}

.copyButton {
  background-color: #428c9f;
  color: white;
  border: none;
  padding: 8px 12px;
  margin-left: 8px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.copyButton:hover {
  background-color: #357286;
}

/* Grupo de header: videoBanner y downloadButtonDiv */
.headerCreateLinksDiv {
  display: flex;
  gap: 10px;
  align-items: center; /* Alinea verticalmente los elementos en la fila */
  justify-content: space-between; /* Espacia los elementos para ocupar todo el ancho */
}

.videoBanner {
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #eaf7fa;
  border: 1px solid #b3e5fc;
  border-radius: 8px;
  flex: 1; /* Permite que el banner ocupe el espacio disponible */
}

.videoText {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.videoIcon {
  margin-right: 10px;
  font-size: 1.5rem;
}

.arrowDown {
  margin-left: 10px;
  font-size: 1.5rem;
  color: #428c9f;
  animation: bounce 2s infinite;
}

.downloadButtonDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #eaf7fa;
  border: 1px solid #b3e5fc;
  padding: 10px;
  border-radius: 8px;
  flex: 0 0 auto; /* Evita que el botón se estire */
}

.downloadButton {
  background-color: #428c9f;
  border-radius: 8px;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.downloadButton:hover {
  background-color: #357286;
}

.pdfIcon {
  margin-right: 8px;
}

/* Media Query para pantallas pequeñas (hasta 770px) */
@media (max-width: 770px) {
  .headerCreateLinksDiv {
    flex-direction: column;
    align-items: stretch; /* Asegura que los elementos ocupen todo el ancho */
  }

  .videoBanner,
  .downloadButtonDiv {
    flex: none; /* Elimina flex-grow y flex-shrink */
    width: 100%; /* Hace que ocupen todo el ancho */
    margin-bottom: 10px; /* Añade espacio entre ellos */
  }

  .downloadButtonDiv {
    justify-content: center; /* Centra el botón en pantallas pequeñas */
  }

  /* Ajustar el tamaño de la fuente para pantallas pequeñas si es necesario */
  .videoText {
    font-size: 1rem;
  }

  .title {
    font-size: 1.3rem;
  }

  .userTypeName {
    font-size: 1rem;
  }

  .linkInput {
    font-size: 0.8rem;
  }

  .copyButton {
    padding: 6px 10px;
    font-size: 0.9rem;
  }

  .downloadButton {
    padding: 8px;
    font-size: 0.9rem;
  }
}

/* Animación de rebote para la flecha */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-2px);
  }
}

/* Media Query adicional para enlaces en pantallas grandes */
@media (min-width: 770px) {
  .linkItem {
    flex-direction: row;
    align-items: center;
  }

  .userTypeInfo {
    flex: 1;
    margin-bottom: 0;
  }

  .linkContainer {
    flex: 2;
  }
}
