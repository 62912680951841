/* src/styles/Company Dashboard styles/Companies Services styles/CompanyServicesTabs.module.css */

/* Contenedor principal de la tabla en CompanyServices */
.companyServicesTableContainer {
  overflow-x: auto;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
}

/* Tabla completa en CompanyServices */
.companyServicesTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
}

/* Cabeceras de columna en CompanyServices */
.companyServicesHeader,
.companyServicesUserTypeHeader {
  background-color: #428c9f;
  color: #ffffff;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
  border-radius: 5px 5px 0 0;
  white-space: nowrap;
  font-size: 1.2em; /* Aumentado desde el tamaño original */
}

/* Celdas de datos en CompanyServices */
.companyServicesServiceName {
  padding: 15px; /* Aumentado de 12px a 15px para más espacio */
  text-align: left;
  color: #333;
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
  background-color: #ffffff;
  position: sticky;
  left: 0;
  z-index: 1;
  font-size: 1.1em; /* Aumentado desde el tamaño original */
}

.companyServicesHeader {
  position: sticky;
  left: 0;
  z-index: 1;
}

/* Iconos de chequeo en CompanyServices */
.companyServicesCheckIcon {
  text-align: center;
  font-size: 1.4em; /* Aumentado desde 1.2em */
  padding: 15px; /* Aumentado de 12px a 15px para más espacio */
  border-bottom: 1px solid #f0f0f0;
  color: #428c9f;
}

/* Celda vacía en CompanyServices */
.companyServicesEmptyCell {
  background-color: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  font-size: 1.1em; /* Aumentado para mayor legibilidad */
  color: #555555; /* Asegura buen contraste */
  padding: 15px; /* Aumentado de 12px a 15px */
}

/* Responsividad para pantallas pequeñas */
@media (max-width: 768px) {
  .companyServicesTableContainer {
    overflow-x: scroll;
  }

  .companyServicesTable th,
  .companyServicesTable td {
    padding: 12px; /* Ajustado para mejor legibilidad en pantallas pequeñas */
    font-size: 1em; /* Aumentado ligeramente */
  }

  /* Asegurar que la primera columna "Services" quede visible */
  .companyServicesServiceName {
    background-color: #ffffff;
    min-width: 150px; /* Asegura que la columna tenga un ancho mínimo */
    font-size: 1em; /* Aumentado ligeramente */
  }
}

/* Responsividad para pantallas medianas y grandes */
@media (min-width: 768px) {
  .companyServicesTable th,
  .companyServicesTable td {
    padding: 15px; /* Aumentado para pantallas medianas */
    font-size: 1.1em; /* Aumentado */
  }

  .companyServicesServiceName {
    font-size: 1.2em; /* Aumentado */
  }

  .companyServicesCheckIcon {
    font-size: 1.4em; /* Aumentado */
    padding: 15px; /* Aumentado */
  }

  .companyServicesEmptyCell {
    font-size: 1.1em; /* Aumentado */
    padding: 15px; /* Aumentado */
  }
}

@media (min-width: 1024px) {
  .companyServicesTable th,
  .companyServicesTable td {
    padding: 20px; /* Aumentado para pantallas grandes */
    font-size: 1.2em; /* Aumentado */
  }

  .companyServicesServiceName {
    font-size: 1.3em; /* Aumentado */
  }

  .companyServicesCheckIcon {
    font-size: 1.6em; /* Aumentado */
    padding: 15px; /* Mantiene el padding */
  }

  .companyServicesEmptyCell {
    font-size: 1.2em; /* Aumentado */
    padding: 15px; /* Mantiene el padding */
  }
}
