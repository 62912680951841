.costCalculatorNet__calculatorForm {
  flex: 1;
  display: grid;
  gap: 20px;
  max-width: 1100px;
  padding: 2rem;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background: linear-gradient(to bottom, #428c9f, #ffffff);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.costCalculatorNet__periodSelector {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  background-color: #eaf4f6;
  border-radius: 8px;
  border: 1px solid #c9e1e4;
}

.costCalculatorNet__topInputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.costCalculatorNet__inputGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.costCalculatorNet__expensesSection {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.costCalculatorNet__expenseItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #f3f8fa;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #d1e3e6;
}

.costCalculatorNet__expenseDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.costCalculatorNet__input {
  padding: 0.8rem;
  border: 1px solid #cdd6d8;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s;
  text-align: center;
}

.costCalculatorNet__calculateButton {
  background-color: #428c9f;
  color: white;
  border: none;
  padding: 12px 25px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
  display: block;
  margin:  auto;
  transition: background-color 0.3s, transform 0.3s;
}

.costCalculatorNet__calculateButton:hover {
  background-color: #337586;
}

.costCalculatorNet__componentCalculator {
  padding: 15px 10px;
  max-width: 1500px;
  margin: 0 auto;
  text-align: center;
  color: #333;
}

.costCalculatorNet__componentCalculator h2 {
  color: #428c9f;
  font-size: 1.6rem;
  margin-bottom: 15px;
}

.costCalculatorNet__calculatorContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.costCalculatorNet__calculatorForm:hover {
  background-color: #e4edf2;
  background: linear-gradient(to bottom, #428c9f, #ffffff);
  border-color: #428c9f;
}

.costCalculatorNet__periodSelector label {
  font-size: 0.95rem;
  color: #555;
  display: flex;
  align-items: center;
  gap: 5px;
}

.costCalculatorNet__inputGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 0.95rem;
  color: white;
}

.costCalculatorNet__inputGroup input {
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.95rem;
  box-sizing: border-box;
  align-self: center;
  width: fit-content;
}

.costCalculatorNet__input:hover,
.costCalculatorNet__input:focus {
  border-color: #428c9f;
  outline: none;
}

.costCalculatorNet__icon {
  font-size: 1.5rem;
  color: #428c9f;
}

.costCalculatorNet__tooltipContainer {
  position: relative;
  display: inline-block;
}

.costCalculatorNet__infoIcon {
  font-size: 0.75rem;
  margin-left: 6px;
  cursor: pointer;
  color: #428c9f;
}

.costCalculatorNet__tooltipContainer {
  display: inline-block;
  position: relative;
}

.costCalculatorNet__tooltip {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.costCalculatorNet__tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.costCalculatorNet__tooltipContainer:hover .costCalculatorNet__tooltip {
  visibility: visible;
  opacity: 1;
}

.costCalculatorNet__results {
  flex: 1;
  background-color: #ffffff;
  border: 1px solid #428c9f;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  background: linear-gradient(to bottom, #e4edf2, #ffffff);
  min-width: 300px;
}

.costCalculatorNet__results h3 {
  color: #428c9f;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.costCalculatorNet__results p {
  font-size: 0.95rem;
  margin-bottom: 10px;
}

.costCalculatorNet__summary {
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #428c9f;
}

.costCalculatorNet__summary {
  background: linear-gradient(135deg, #f3f8fa, #f3f8fa);
  border-radius: 12px;
  padding: 10px;
  margin: 20px 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  color: #333;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.costCalculatorNet__summary:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}

.costCalculatorNet__row {
  display: flex;
  justify-content: space-around;
}



.costCalculatorNet__warning {
  color: rgb(238, 174, 55);
  font-weight: bold;
}

.costCalculatorNet__chartContainer {
  width: 100%;
  text-align: center;
  padding-right: 10px;
}

.costCalculatorNet__chartContainer h4 {
  color: #428c9f;
  font-size: 1rem;
  margin-top: 20px;
}

.costCalculatorNet__progressBarContainer h4 {
  color: #428c9f;
  font-size: 1rem;
  margin-bottom: 10px;
}

.costCalculatorNet__guideComment {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 15px;
  text-align: left;
}

.costCalculatorNet__progressBarContainer {
  margin-top: 15px;
  text-align: center;
}

.costCalculatorNet__progressBarContainer label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.costCalculatorNet__progressBar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 20px;
  margin-bottom: 5px;
}

.costCalculatorNet__progress {
  height: 100%;
  background-color: #428c9f;
}

.costCalculatorNet__progressBarContainer p {
  margin: 0;
  font-size: 0.95rem;
  color: #555;
}

.costCalculatorNet__customTooltip {
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.costCalculatorNet__tooltipName {
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.costCalculatorNet__tooltipValue {
  color: #555;
}

@media (max-width: 600px) {
  .costCalculatorNet__topInputs {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .costCalculatorNet__inputGroup {
    text-align: center;
  }

  .costCalculatorNet__expenseDetails label {
    font-size: 0.9rem;
  }

  .costCalculatorNet__results p,
  .costCalculatorNet__progressBarContainer p {
    font-size: 0.85rem;
  }

  .costCalculatorNet__progressBar {
    height: 15px;
  }
}

.costCalculatorNet__wrapper {
  padding: 2rem;
  margin: auto;
  border-radius: 12px;
  background: linear-gradient(to bottom, #e4edf2, #ffffff);
  border: 2px solid #428c9f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
