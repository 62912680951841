.nav {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  padding: 15px 25px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logoAvatarContainer {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
}

.logoName {
  height: 50px;
  width: 140px;
}

.separator {
  width: 1px;
  height: 60px;
  background-color: #428c9f;
  margin: 0 15px;
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.avatarBox {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 8px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #428c9f;
}

.diagonalSeparator {
  width: 1px;
  height: 60px;
  background-color: transparent;
  border-left: 1px solid #428c9f;
  transform: rotate(20deg);
  margin: 0 15px;
}

.userName {
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.navList {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

.navList li a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.active a {
  border-bottom: 2px solid #428c9f;
}

.loginButton,
.registrationButton,
.navBarButton {
  background-color: #428c9f;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 15px;
  margin: 4px 2px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
}

.registrationButton {
  background-color: #d6e4ec;
  color: black;
}

.registrationButton:hover,
.navBarButton:hover {
  background-color: #357384;
  color: white;
}

.languageSelector {
  display: flex;
  align-items: center;
  margin: 0px;
}

.languageSelector select {
  appearance: auto;
  background-color: white;
  border-radius: 15px;
  cursor: pointer;
  transition: border-color 0.3s;
  width: auto;
  margin: 0px;
}

.languageSelector select:hover,
.languageSelector select:focus {
  border-color: #357384;
  outline: none;
}

.languageSelector label {
  margin-right: 10px;
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 28px;
  color: #428c9f;
  border: none;
  background: none;
}

.navMenu {
  display: flex;
  align-items: center;
}

.navMenuActive {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: absolute;
  top: 75px;
  left: 0;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.disabledLinkContainer {
  position: relative;
  display: inline-block;
  cursor: default;
}

.disabledLink {
  color: #c0baba;
  pointer-events: none;
  display: inline-flex;
  align-items: center;
}

.disabledLinkText {
  color: #d3d3d3;
  display: inline-block;
  position: relative;
  cursor: default;
}

.lockIcon {
  margin-left: 5px;
  color: gray;
}

.tooltipText {
  visibility: hidden;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.tooltipText::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
}

.disabledLinkContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1030px) and (min-width: 935px) {
  .nav {
    padding: 10px 20px;
  }

  .separator,
  .diagonalSeparator {
    height: 50px;
  }

  .avatar {
    width: 45px;
    height: 45px;
  }

  .userName {
    font-size: 1.2rem;
  }

  .navList {
    gap: 0.5rem;
    font-size: 1.2rem;
  }

  .loginButton,
  .registrationButton,
  .navBarButton {
    padding: 6px 12px;
    font-size: 1.2rem;
    margin: 4px 1px;
  }

  .languageSelector {
    font-size: 1.2rem;
  }

  .languageSelector select {
    padding: 4px 8px;
    font-size: 1.2rem;
  }
}

@media (max-width: 935px) {
  .nav {
    padding: 10px 20px;
  }

  .hamburger {
    display: block;
  }

  .navMenu {
    display: none;
  }

  .navMenuActive {
    display: flex;
  }

  .navList {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .navList li {
    margin-bottom: 10px;
  }

  .navList li a {
    padding: 10px 0;
    width: 100%;
  }

  .tooltipText {
    top: 50%;
    left: 100%;
    transform: translateX(10px) translateY(-50%);
    width: 200px;
    text-align: left;
  }

  .tooltipText::after {
    top: 50%;
    left: 0%;
    transform: translateX(-100%) translateY(-50%);
    border-color: transparent rgba(0, 0, 0, 0.8) transparent transparent;
  }
}
