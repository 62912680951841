/* src/styles/Curiosities Calendar styles/CuriositiesCalendar.module.css */

.curiositiesCalendarContainer {
  display: flex;
  flex-direction: row;
  font-family: Arial, sans-serif;
  max-width: 1350px; /* Ajuste del ancho máximo */
  margin: 0 auto;
  padding: 20px;
  gap: 20px; /* Espacio entre calendario y sección de información */
}

.curiositiesCalendarCalendar {
  width: 90%; /* Ajuste para que ambos contenedores quepan dentro del max-width */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.curiositiesCalendarInfoSection {
  display: flex;
  flex-direction: column;
  gap: 30px; /* Espacio entre título/descripción y curiosidad diaria */
}

.curiositiesCalendarTitleDescription {
  background-color: #ffffff; /* Fondo blanco para contraste */

  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.curiositiesCalendarTitle {
  font-size: 2.5em; /* Tamaño grande para el título */
  color: #428c9f;
  margin-bottom: 10px;
  text-align: center;
}

.curiositiesCalendarDescription {
  font-size: 1.2em; /* Tamaño mayor para la descripción */
  color: #555;
  text-align: center;
}

.curiositiesCalendarDailyCuriosity {
  background-color: #f0f8ff; /* Fondo azul claro para diferenciar */
  border: 2px solid #428c9f; /* Borde del color principal */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.curiositiesCalendarCuriosityTitleContainer {
 width: auto;
}

.curiositiesCalendarCuriosityTitle {
  font-size: 1.8em; /* Tamaño aumentado para el título de curiosidad */
  color: #428c9f;
  margin-bottom: 10px;
}

.curiositiesCalendarCuriositySection {
  /* Ya no es necesario aplicar estilos aquí, ya que la estructura está manejada por los nuevos contenedores */
}

.curiositiesCalendarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.curiositiesCalendarNavButton {
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #428c9f;
}

.curiositiesCalendarCurrentMonth {
  font-size: 2em;
  font-weight: bold;
  color: #428c9f;
}

.curiositiesCalendarDaysRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.curiositiesCalendarDay {
  flex: 1;
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
  border-bottom: 2px solid #ddd;
  color: #428c9f;
  font-size: 1em;
}

.curiositiesCalendarBody {
}

.curiositiesCalendarRow {
  display: flex;
}

.curiositiesCalendarCell {
  flex: 1;
  height: 100px;
  border: 1px solid #ddd;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.curiositiesCalendarSelected {
  background-color: #ADD8E6;
}

.curiositiesCalendarOutOfMonth {
  color: #ccc;
}

.curiositiesCalendarTodayButton {
  padding: 12px 24px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #428c9f;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.1em;
  transition: background-color 0.3s ease;
}

.curiositiesCalendarTodayButton:hover {
  background-color: #32718a;
}

.curiositiesCalendarDot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #428c9f;
  position: absolute;
  bottom: 10px;
}

.curiositiesCalendarLogo {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 10px;
}

.curiositiesCalendarLogoGray {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 10px;
  filter: grayscale(100%);
}

/* src/styles/Curiosities Calendar styles/CuriositiesCalendar.module.css */

/* Ajustes para la leyenda */

.curiositiesCalendarLegend {
  background-color: #f9f9f9; /* Fondo ligero */
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9em;
  color: #555;
  gap: 15px;
}

.curiositiesCalendarLegendItem {
  display: flex;
  align-items: center; /* Alineación vertical central */
}

.curiositiesCalendarLegendItem img {
  width: 25px;
  height: 25px;
  margin-right: 8px; /* Espacio entre el ícono y el texto */
}
.curiositiesCalendarLegendDot {
  width: 20px;
  height: 20px;
  margin-right: 8px; /* Espacio entre el ícono y el texto */
}

.curiositiesCalendarDot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #428c9f;
  margin-right: 8px; /* Espacio entre el círculo y el texto */
  display: inline-block; /* Asegura que se comporte como un elemento en línea */
  vertical-align: middle; /* Alineación vertical */
}

/* Estilos para el punto en la leyenda */
.curiositiesCalendarLegendDot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #428c9f;
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
}

/* Mantén la clase existente para el punto en las celdas del calendario */
.curiositiesCalendarDotCell {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #428c9f;
  position: absolute;
  bottom: 10px;
}




/* Responsive Styles */
@media (max-width: 1024px) {
  .curiositiesCalendarContainer {
    flex-direction: row;
  }

  .curiositiesCalendarCalendar {
  }

  .curiositiesCalendarInfoSection {
    padding: 30px;
  }

  .curiositiesCalendarTitleDescription {
    margin-bottom: 25px;
  }
}

@media (max-width: 768px) {
  .curiositiesCalendarContainer {
    flex-direction: column-reverse;
  }

  .curiositiesCalendarCalendar {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
  }

  .curiositiesCalendarInfoSection {
    width: 100%;
    padding: 20px;
  }

  .curiositiesCalendarDay {
    font-size: 1em;
    padding: 10px 0;
  }

  .curiositiesCalendarCurrentMonth {
    font-size: 1.5em;
  }

  .curiositiesCalendarNavButton {
    font-size: 1.5em;
  }

  .curiositiesCalendarCell {
    height: 90px;
    padding: 10px;
  }

  .curiositiesCalendarTodayButton {
    padding: 10px 20px;
    font-size: 1em;
  }

  .curiositiesCalendarDot {
    width: 12px;
    height: 12px;
  }

  .curiositiesCalendarLogo,
  .curiositiesCalendarLogoGray {
    width: 20px;
    height: 20px;
  }

  .curiositiesCalendarLegendItem img {
    width: 15px;
    height: 15px;
  }

  /* Ajustes para pantallas pequeñas */
  .curiositiesCalendarTitle {
    font-size: 2em;
  }

  .curiositiesCalendarDescription {
    font-size: 1em;
  }

  .curiositiesCalendarCuriosityTitle {
    font-size: 1.5em;
  }

  .curiositiesCalendarCuriositySection p {
    font-size: 1em;
  }
}
