.costCalculatorMain__tabsContainer {
  width: 100%;
  margin: 25px auto;
}

.costCalculatorMain__tabsContainer h2{
  text-align: center;
}

.costCalculatorMain__tabHeaders {
  display: flex;
  max-width: 800px;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto 20px auto;
  position: relative;
}

.costCalculatorMain__tabButton {
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
}

.costCalculatorMain__tabButton:hover {
  color: #428c9f;
}

.costCalculatorMain__tabButton::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #428c9f;
  transition: width 0.3s ease, left 0.3s ease;
}

.costCalculatorMain__tabButton.active::after {
  width: 100%;
  left: 0;
}

.costCalculatorMain__tabContent {
  padding: 20px;
}

