.tooltipIcon {
    cursor: pointer;
    font-size: 16px;
    color: #007bff;
    margin-right: 8px;
    font-weight: normal;
  }

  .tooltipContent {
    position: absolute;
    top: 100%; /* Posiciona el tooltip debajo del icono */
    left: 0;
    z-index: 1000; /* Asegura que el tooltip esté por encima de otros elementos */
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    width: 280px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-top: 5px; /* Espacio entre el icono y el tooltip */
    border-radius: 4px;
  }
  
  .validationList {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .validationItem {
    margin-bottom: 4px;
    font-size: 14px;
  }
  
  .valid {
    color: green;
  }
  
  .invalid {
    color: red;
  }
  