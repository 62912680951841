/* Email confirmation page container */
.emailConfirmationSentContainer {
  text-align: center;
  margin-top: 80px;
  margin-left: 15%;
  margin-right: 15%;
}

/* Styling for the logo */
.logoEmailConfirmationSent {
  max-width: 200px;
  margin: 20px auto;
  height: auto;
}

/* Text box container styling */
.textBoxContainer {
  background-color: #f3f4f6; /* Light gray background */
  border-radius: 10px;
  padding: 20px;
  max-width: 500px;
  margin: 20px auto; /* Center the text box */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

/* Text styling inside the text box */
.textBoxContainer p {
  color: #333; /* Darker text for readability */
  font-size: 16px;
  line-height: 1.5;
  margin: 0; /* Remove default margin */
}

/* Container for the additional image below the text */
.emailConfirmationSentImagContainer {
}

/* Styling for the additional image */
.emailConfirmationSentImag {
  max-width: 300px;
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional rounded corners */
}
