.demoWebContainer {
  text-align: center;
}

.demoWebContainer h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.8em; /* Adjust font size as needed */
  color: #428c9f;
}

.demoWebContainer p {
  color: #555;
  font-size: 1.2em;
  line-height: 1.4;
  margin-bottom: 30px;
}

.videoContainer {
  position: relative;
  padding-top: 56.25%; /* Adjust padding-top to make the video larger, 56.25% is a 16:9 aspect ratio */
  max-width: 100%; /* Ensure the video container can expand fully */
  margin: 0 auto; /* Center the container */
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px; /* Rounded corners */
}
