/* CommutingDetails.module.css */
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
}

.imageContainer {
  flex: 1;
}

.tasksImage {
  max-width: 100%;
  border-radius: 15px;
}

.content {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  /*gap: 20px;*/
  padding: 20px;
  border-radius: 15px;
  /* background-color: #f5f5f5; Ajuste para el fondo de la sección de contenido */
}

.tasksList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.taskItem {
  padding: 10px;
  border: 1px solid #428c9f;
  border-radius: 15px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.taskItem:hover {
  background-color: #e4edf2;
}

.taskHeader {
  justify-content: space-between; /* Alinea el título a la izquierda y el checkbox a la derecha */
  display: flex;
  align-items: center;
  gap: 10px;
  /*font-size: 1.3em;*/
}

.taskTitle {
  font-weight: bold;
  text-align: left;
  
}

.taskDescription {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  text-align: left; /* Alinea el texto justificado */
}

.taskDescription h3 {
  margin-top: 30px;
  color: #555; /* Color del texto del párrafo */
}

.taskDescription p {
  color: #555; /* Color del texto del párrafo */
  /*font-size: 1.1em;*/
  line-height: 1.5; /* Ajusta la altura de línea para una mejor legibilidad */
  margin-bottom: 10px; /* Espaciado inferior entre párrafos */
}

.taskDescription ul {
  color: #555; /* Color del texto del párrafo */
  line-height: 1.5; /* Ajusta la altura de línea para una mejor legibilidad */
  list-style-type: disc;
  padding-left: 20px;
}

.taskDescription li {
  margin-bottom: 5px; /* Añade un poco de espacio entre los bullet points */
}

.checkbox {
  margin-left: auto; /* Asegura que el checkbox se mueva hacia la derecha */
}

.containerDetailsButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px; /* Espacio entre los botones, puedes ajustarlo según tus preferencias */
}

.actionButton {
  font-size: 1.2em;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  border-radius: 15px;
  background-color: #428c9f;
  color: white;
  cursor: pointer;
  border: none;
}

/* Ajustes para responsividad */
@media (max-width: 1050px) {
  .container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .imageContainer,
  .content {
    width: 100%;
  }

  .tasksImage {
    max-width: 90%;
    height: auto;
    margin-left: auto;
  }
}

/* MovingCommutingDetails.module.css */
.container {
  padding: 20px;
  line-height: 1.6;
}

.title {
  /*color: #428c9f;
  margin-top: 20px;
  margin-bottom: 10px;*/
}

.subtitle {
  /*margin-bottom: 10px;*/
}

.tasksList {
  display: flex;
  flex-wrap: wrap;
}

.groupContainer {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.groupHeader {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  color: #428c9f; /* Cambia el color según tu preferencia */
}

.groupName {
  /* Estilos para el nombre del grupo */
  font-size: 1.2rem;
  font-weight: bold;
  color: #428c9f; /* Cambia el color según tu preferencia */
}

.groupTasks {
  margin-top: 10px;
}

.groupIcon {
  /* Estilos para el icono del grupo (el ícono de cuadro marcado) */
  /* Estilos adicionales cuando el grupo está completado (chequeado) */
  color: white; /* Cambia el color según tu preferencia */
  background-color: #428c9f; /* Cambia el color de fondo según tu preferencia */
  padding: 3px; /* Ajusta el relleno según sea necesario */
}

.groupIcon.checked {
  /* Estilos adicionales cuando el grupo está completado (chequeado) */
  color: white; /* Cambia el color según tu preferencia */
  background-color: #428c9f; /* Cambia el color de fondo según tu preferencia */
  padding: 3px; /* Ajusta el relleno según sea necesario */
}

.taskItem {
  margin-top: 10px;
}

.taskHeader {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.checkbox {
  margin-left: 10px;
}

.taskDescription {
  margin-top: 10px;
}

.toggleButtonsContainer {
  display: flex;
  justify-content: flex-end; 
  align-self: center;
}

.toggleButton {
  display: flex;
  align-items: center;
  margin-left: 16px; /* Espacio entre los botones */
  padding: 12px; /* Aumenta el tamaño del botón */
  cursor: pointer;
  border: none;
  background: transparent;
  color: #333; /* Color del texto */
  font-size: 16px; /* Aumenta el tamaño del texto */
  transition: color 0.3s ease-in-out;
}

.toggleIcon {
  margin-right: 8px; /* Espacio entre el icono y el texto */
}

.iconPhone {
  margin-right: 8px; /* Espacio entre el icono y el texto */
}

.toggleOn {
  color: #28a745; /* Color cuando el interruptor está encendido */
}

.toggleOff {
  color: orange; /* Color cuando el interruptor está apagado */
}


/* src/styles/Dashboard styles/TasksCompanies.module.css */

.tasksCompanies {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
}

.personalInfo,
.moveInfo {
  margin-bottom: 20px;
}

.tasksList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.taskItem {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 15px;
  background-color: #f9f9f9;
}

.completed {
  color: green;
}

.pending {
  color: orange;
}

.taskDescription {
  margin: 10px 0;
}


/* En MovingCommutingDetails.module.css */

.sidebar {
  width: 250px;
  float: left;
  margin-right: 20px;
}

.individualCard {
  background-color: #fff;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  padding: 15px;
  cursor: pointer;
  transition: transform 0.3s;
}

.individualCard:hover {
  transform: scale(1.02);
}

.selectedCard {
  border-color: #007bff;
}

.cardFront, .cardBack {
  /* Estilos para el frente y reverso de la tarjeta */
}

.cardBack {
  margin-top: 10px;
  background-color: #f9f9f9;
  padding: 10px;
}
