/* src/components/CompanyDashboard/CompanyDashboard.module.css */
.companyDashboard {
  display: flex;
  background-color: #f0f4f8;
  width: 100%;
  height: 90vh;
  overflow-y: auto;
}

.toggleButton {
  display: none;
  position: fixed;
  background-color: #428c9f;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1001;
  transition: background-color 0.3s;
}

.toggleButton:hover {
  background-color: #32718b;
}

.companyDashboardSidebar {
  width: 250px;
  background-color: #428c9f;
  color: #ffffff;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  height: 90vh;
  overflow-y: auto;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.companyDashboardSidebarOpen {
  transform: translateX(0);
}

.companyDashboardSidebarCollapsed {
  width: 100px;
}

.companyDashboardSidebarCollapsed .companyDashboardLogo {
  font-size: 18px;
}

.companyDashboardSidebarCollapsed .sidebarNav ul li span.companyDashboardText {
  display: none;
}

.companyDashboardSidebarCollapsed .collapseButton {
  align-self: flex-end;
}

.collapseButton {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  align-self: flex-end;
  transition: transform 0.3s;
}

.collapseButton:hover {
  transform: scale(1.2);
  background-color: #32718b;
  border-radius: 4px;
}

.sidebarNav {
  flex: 1;
}

.companyDashboardNavList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.bottomNav {
  margin-top: auto;
}

.bottomNav ul li {
  margin-top: 10px;
}

.companyDashboardMainContent {
  flex: 1;
  padding: 30px;
  background-color: #f0f4f8;
  overflow-y: auto;
  color: #2c3e50;
  transition: margin-left 0.3s ease-in-out, padding 0.3s ease-in-out;
  margin-left: 0;
  height: 100%;
}

.overlay {
  display: none;
}

@media (min-width: 769px) {
  .toggleButton {
    display: none;
  }

  .companyDashboardSidebar {
    transform: translateX(0);
    position: relative;
    box-shadow: none;
  }

  .companyDashboardMainContent {
  }

  .overlay {
    display: none;
  }
}

@media (max-width: 768px) {
  .toggleButton {
    display: block;
  }

  .toggleButtonOpen {
    top: 15px;
    left: 15px;
    display: none;
  }

  .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .companyDashboardSidebar {
    transform: translateX(-100%);
  }

  .companyDashboardSidebarOpen {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }

  .companyDashboardMainContent {
    margin-left: 0;
    padding: 30px;
  }
}

.content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.companyDashboardNavItem {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
  color: #ffffff;
  font-size: 16px;
}

.companyDashboardNavItem:hover {
  background-color: #32718b;
  color: #ffffff;
}

.companyDashboardActive {
  background-color: #32718b;
  color: #ffffff;
}

.companyDashboardIcon {
  margin-right: 12px;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.companyDashboardText {
  flex: 1;
  font-weight: 500;
}
