/* BeforeAndAfter.module.css */

.beforeAndAfter {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-top: 40px;
  max-width: 95%;
  margin-left: 10%;
  margin-right: 10%;
}

.allContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  flex-direction: column;
}
.allContainer h1 {
  font-size: 3em;
}

.innerContainer {
  gap: 40px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-direction: row;
}

.leftContainer {
  flex: 1;
  min-width: 300px;
  padding: 20px;
  border-radius: 5%;
  background-color: #edf3f4;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.leftContainer h2 {
  color: #5f6161;
  font-size: 2em;
  margin-bottom: 30px;
  text-align: left;
}

.rightContainer {
  flex: 1;
  min-width: 300px;
  padding: 20px;
  border-radius: 5%;
  background-color: #ccf7f4;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rightContainer h2 {
  font-size: 2em;
  color: #428c9f;
  margin-bottom: 30px;
  text-align: left;
}

.leftContainer ul,
.rightContainer ul {
  font-size: 1.2em;
  text-align: left;
  padding-left: 5px;
}
span {
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.highlighted {
  color: #428c9f;
  font-weight: bold;
}

@media (max-width: 1300px) {
  .beforeAndAfter,
  .allContainer,
  .innerContainer {
    flex-direction: column;
  }
}

.containerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2 {
  margin-right: 10px;
}

.logo {
  margin-top: -30px;
  width: 50px;
  height: auto;
}

@media (max-width: 768px) {
  .beforeAndAfter,
  .allContainer,
  .innerContainer {
    flex-direction: column;
  }
}

.containerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2 {
  margin-right: 10px;
}

.logo {
  margin-top: -30px;
  width: 50px;
  height: auto;
}
