/* ProgressBar.module.css */
.progressBarContainer {
  width: 100%;
  height: 30px;
  background-color: #ddd;
  border-radius: 15px;
  position: relative;
  overflow: hidden;

}

.progressBar {
  top: 50%;
  bottom: 50%;
  background-color: #428c9f;
  border-radius: 5px;
  height: 5px;
  margin-top: 12.5px;
}


.percentageLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-weight: bold;
}

.percentageProgressBarContainer {
  width: 100%;
  height: 30px;
  /*margin-top: -20px; 
  margin-bottom: -20px; Ajusta según sea necesario para la separación 
  border-radius: 15px;*/
  overflow: hidden;
  position: relative;
}

.arrowIcon {
  position: absolute;
  top: 50%; /* Ajusta la posición vertical según sea necesario */
  transform: translate(-50%, -50%) scale(1.5); /* Ajusta la escala según sea necesario para hacerla más grande */
  color: black;

}

.completionMessage {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  /*margin-top: 10px;
  color: #428c9f;*/
}


.icon {
  margin-right: 10px; /* Ajusta la distancia entre el icono y el texto */
}

/* ProgressBar.module.css */
.progressBarMarker {
  position: absolute;
  width: 20px; /* Ajusta el tamaño del marcador según tu preferencia */
  height: 20px; /* Ajusta el tamaño del marcador según tu preferencia */
  background-color: #428c9f; /* Puedes ajustar el color según tu preferencia */
  border-radius: 50%;
  top: 50%;
  margin-right: 10px;
  transform: translateY(-50%);
  transition: left 0.3s ease, width 0.3s ease, height 0.3s ease; /* Añadido para animaciones suaves */
}

