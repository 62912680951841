/* src/styles/Company Dashboard styles/Companies Services styles/IntegrationTab.module.css */

.integrationServiceContainer {
    padding: 40px 20px; /* Reducido para títulos más pequeños */
    background-color: #fff; /* Fondo azul claro */
    border-radius: 10px;
    max-width: 1400px; /* Mayor anchura en pantallas grandes */
    margin: 0 auto;
    color: #333333; /* Texto oscuro para mejor contraste */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .integrationServiceHeading {
    font-size: 2em; /* Tamaño reducido */
    margin-bottom: 10px; /* Margen reducido */
    color: #428c9f; /* Color de marca para el título */
    text-align: center;
  }
  
  .integrationServiceDescription {
    font-size: 1em; /* Tamaño reducido */
    margin-bottom: 30px; /* Margen reducido */
    color: #555555; /* Gris oscuro para mejor legibilidad */
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .integrationServiceGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .integrationServiceCard {
    background-color: #ffffff; /* Fondo claro para las tarjetas */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid #428c9f;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .integrationServiceCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .integrationServiceIconContainer {
    background-color: #428c9f; /* Fondo con color de marca */
    border-radius: 50%;
    width: 80px; /* Tamaño aumentado */
    height: 80px; /* Tamaño aumentado */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px auto;
  }
  
  .integrationServiceIcon {
    color: #ffffff; /* Íconos blancos para contraste */
    font-size: 3em; /* Tamaño de ícono aumentado */
  }
  
  .integrationServiceTitle {
    font-size: 1.2em; /* Tamaño de título reducido */
    margin-bottom: 10px; /* Margen inferior */
    color: #428c9f; /* Color de marca para los títulos */
  }
  
  .integrationServiceDescriptionText {
    font-size: 1em; /* Tamaño de descripción */
    color: #555555; /* Gris oscuro para mejor legibilidad */
  }
  
  /* Responsive Styling */
  
  /* Medium Screens */
  @media (min-width: 768px) {
    .integrationServiceContainer {
      padding: 40px 40px;
    }
  
    .integrationServiceHeading {
      font-size: 2.5em;
    }
  
    .integrationServiceDescription {
      font-size: 1.1em;
    }
  
    .integrationServiceGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .integrationServiceIconContainer {
      width: 90px;
      height: 90px;
    }
  
    .integrationServiceIcon {
      font-size: 3.5em;
    }
  
    .integrationServiceTitle {
      font-size: 1.3em;
    }
  
    .integrationServiceDescriptionText {
      font-size: 1.1em;
    }
  }
  
  /* Large Screens */
  @media (min-width: 1100px) {
    .integrationServiceContainer {
      padding: 40px 60px;
    }
  
    .integrationServiceHeading {
      font-size: 3em;
    }
  
    .integrationServiceDescription {
      font-size: 1.3em;
    }
  
    .integrationServiceGrid {
      grid-template-columns: repeat(4, 1fr);
    }
  
    .integrationServiceIconContainer {
      width: 100px;
      height: 100px;
    }
  
    .integrationServiceIcon {
      font-size: 4em;
    }
  
    .integrationServiceTitle {
      font-size: 1.4em;
    }
  
    .integrationServiceDescriptionText {
      font-size: 1.2em;
    }
  }
  