/* HomeVideoText.css */

.videoTextContainer {
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e4edf2;
}

.videoContainer {
  flex: 0.65;
  position: relative;
  padding-top: 21.125%;
  margin: 30px;
}

.videoContainer iframe {
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.textContainer {
  flex: 1;
  padding: 20px;
  text-align: left;
}

.textContainer h1 {
  font-size: 36px;
  margin: 20px 0;
  align-content: center;
  justify-content: center;
}

.textContainer p {
  font-size: 20px;
  color: black;
  margin-bottom: 20px;
  line-height: 1.5;
}

/* HomeVideoText.css */
@media (max-width: 825px) {
  .videoTextContainer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .videoContainer {
    margin-left: 30px auto;
    margin-right: 30px auto;
    margin-top: 10px auto;
    margin-bottom: 10px auto;
    width: calc(100% - 70px);
    padding-top: calc(56.25% - 30px);
    order: -1;
    position: relative;
  }

  .videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-left: 20px auto;
    margin-right: 20px auto;
    margin-top: 10px auto;
    margin-bottom: 10px auto;
  }

  .textContainer {
    max-width: 90%;
    padding: 10px;
    order: 1;
  }
}

.highlighted {
  color: #428c9f;
}

/* IconList.css */
.textContainer ul {
  list-style: none;
  font-size: 20px;
  padding-left: 0;
  text-align: left;
}

.textContainer li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.textContainer ul svg {
  margin-right: 10px;
  color: #428c9f;
}
