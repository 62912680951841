/* src/styles/Authentication/styles/LogIn.module.css */

/* General Styles */

.loginPageContainer {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px; /* Space between image and form */
  padding: 20px;
  min-height: 80vh; /* Ensure the container occupies full screen height */
}

.loginImage {
  max-width: 40%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
  margin: 0 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow to the image for better appearance */
}

.loginContainer, .registrationContainer {
  flex: 1;
  max-width: 500px;
  margin: 10px auto;
  padding: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: white; /* Light background for the form */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow to highlight the form */
}

.formGroup {
  margin-bottom: 20px;
  width: 100%;
}

.formGroup label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.formGroup input[type="checkbox"] {
  width: auto;
  
}

/* Estilo para el input general */
.formGroup input {
  width: 100%;
  padding: 12px;
  border: 1px solid #428c9f;
  border-radius: 8px;
  font-size: 1.1em;
  color: #333;
}

.formGroup input:focus {
  outline: none;
  border-color: #2a6f80;
  box-shadow: 0 0 5px rgba(66, 140, 159, 0.5); /* Highlights the field on focus */
}

/* Botones de inicio de sesión y registro */
.loginButton, .registrationButton {
  background-color: #428c9f;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 1.1em;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 50%; 
  text-align: center;
}

.loginButton:hover, .registrationButton:hover {
  background-color: #357a89;
  transform: translateY(-2px); /* Small elevation effect on hover */
}

.forgotPassword {
  margin-top: 20px;
  text-align: center;
}

.forgotPassword a {
  color: #428c9f;
  text-decoration: none;
  font-weight: bold;
}

.forgotPassword a:hover {
  text-decoration: underline;
}

.title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle {
  text-align: center;
  margin-bottom: 20px;
  font-weight: normal;
}

.link {
  color: #428c9f;
  text-decoration: underline;
}

.captchaContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.icon {
  margin: 0 10px;
}

/* Styles specific to the password input and eye button */

.inputWrapper {
  position: relative;
}

.inputWrapper .input {
  width: 100%;
  padding-right: 40px; /* Adds space for the icon */
}

.eyeButton {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.eyeButton:focus {
  outline: none;
}

.eyeButton svg {
  color: #888; /* Icon color */
  font-size: 1.2em; /* Icon size */
}

/* Checkbox Styles */

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  color: #333;
  flex-wrap: wrap;
}

.checkbox {
  margin-right: 10px;
  width: 16px; /* Reduced width */
  height: 16px; /* Reduced height */
  cursor: pointer;
}


.formGroup {
  margin-bottom: 20px;
  width: 100%;
}

.termsGroup label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
  display: block;
}

.termsGroup input[type="checkbox"] {
  width: auto;
  
}

/* Estilo para el input general */
.termsGroup input {
  width: 100%;
  padding: 12px;
  border: 1px solid #428c9f;
  border-radius: 8px;
  font-size: 1.1em;
  color: #333;
}

.termsGroup input:focus {
  outline: none;
  border-color: #2a6f80;
  box-shadow: 0 0 5px rgba(66, 140, 159, 0.5); /* Highlights the field on focus */
}


.termsLink {
  color: #428c9f;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s;
}

.termsLink:hover {
  color: #357384;
}

.errorMessage {
  color: red;
  font-size: 0.9em;
  margin-top: 10px;
}

.passwordValidationContainer {
  position: relative;
  margin-top: 8px; /* Espacio entre el campo de contraseña y la ayuda */
  z-index: 1; /* Asegura que la ayuda no cubra otros elementos */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .loginPageContainer {
    flex-direction: column-reverse;
    padding: 20px;
  }

  .loginImage {
    max-width: 90%;
    margin: 10px auto;
  }

  .loginContainer, .registrationContainer {
    max-width: 90%;
    margin: 20px auto;
    padding: 30px 20px;
  }
  .checkboxLabel {
    gap: 12px; /* Aumenta el espacio en pantallas pequeñas */
  }
  
  .termsLink {
    flex-basis: 100%; /* Hace que el enlace ocupe el ancho completo */
  }
}
