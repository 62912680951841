.userTypeSelection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  background-color: #f8f9fa;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.image {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  width: 400px;
  cursor: pointer;

  justify-content: space-between;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.card h2 {
  margin: 10px 0;
  font-size: 1.5rem;
}

.card p {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
  padding: 0 10px;
  line-height: 1.4;
}

@media (max-width: 870px) {
  .userTypeSelection {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }

  .card {
    width: 100%;
    max-width: 320px;
    height: auto;
  }
}

