/* CookieHandler.module.css */

.CookiesHandlerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 10px;
  border: 3px solid #ccc;
  border-radius: 15px;
  z-index: 999;
}

.CookiesHandlerIconHeaderWrapper {
  display: flex;
  align-items: center;
}

.CookiesHandlerHeader {
  font-size: 2em;
  margin-top: 15px;
  margin-bottom: 15px;
}

.CookiesHandlerIcon {
  font-size: 2em;
  margin-right: 10px;
}

.CookiesHandlerText {
  text-align: left;
  font-size: 1.2em;
  margin-bottom: 15px;
}

.CookiesHandlerButtonGroup {
  display: flex;
  gap: 10px;
}

.CookiesHandlerButton {
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #428c9f;
  border-radius: 15px;
  padding: 10px 20px;
  color: white;
  font-size: 1.2em;
}

.CookiesHandlerLink {
  margin-bottom: 20px;
  text-decoration: underline;
  font-size: 1.2em;
  cursor: pointer;
  font-weight: bold;
}
