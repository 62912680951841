/* ContactUs.module.css */

.contactUsLayout {
  display: flex;
  justify-content: space-between; /* Distribuye el espacio entre las secciones */
  align-items: flex-start; /* Alinea los elementos al inicio */
  gap: 20px; /* Espacio entre las secciones */
  flex-wrap: wrap; /* Permite que las secciones se apilen verticalmente en pantallas pequeñas */
}

.whatsappLink {
  display: inline-flex; /* Muestra el ícono y el texto en línea */
  align-items: center; /* Alinea verticalmente el ícono con el texto */
  color: #555; /* Color oficial de WhatsApp */
  text-decoration: none; /* Elimina el subrayado del enlace */
  font-size: 18px; /* Tamaño de fuente del texto */
  gap: 8px; /* Espacio entre el ícono y el texto */
}
.whatsappLink:hover {
  text-decoration: underline; /* Subraya el enlace al pasar el mouse */
}
.whatsappIcon {
  color: #428c9f; /* Azul para el icono */
}

/* ContactUs.module.css */
.calendlyContainer {
  background-color: #fbfbfb; /* Fondo claro para el contenedor */
  flex: 2; /* Hace que ambas secciones ocupen espacio disponible de manera equitativa */
  min-width: 300px; /* Establece un ancho mínimo para cada sección */
  border-radius: 8px; /* Bordes redondeados para suavizar el contenedor */
  margin-top: 20px;
  padding: 20px;

}

.calendlyContainer iframe {
  margin-top: 20px;
  background-color: #ffffff;
  height: 700px; 
  width: 100%; 
  border: none;
}

.calendlyContainer h2 {
  margin-top: 40px;
  color: #428c9f; /* Color oscuro para el título */
  text-align: center; /* Centrar el título */
  font-size: 2.5em;
}

.calendlyContainer p {
  text-align: center; /* Centrar texto explicativo */
  color: #555; /* Color gris para el texto */
  font-size: 1.2em;
  line-height: 1.5;
}

.calendlyContainer span {
  color: black;
}


@media (max-width: 120px) {
  .contactUsLayout {
    flex-direction: column; /* Apila los contenedores en pantallas pequeñas */
  }
  .calendlyContainer {
    width: 90%; /* Ajusta ambos contenedores para ocupar la mayoría de la pantalla */
    margin: 20px auto; /* Centrado y espacio uniforme */
  }
}
