/* PrivacyPolicy.module.css */
.container {
    padding: 20px;
    line-height: 1.6;
    max-width: min(1400px,90%);
    margin: auto;
  }
  
  .header {
    color: #428c9f;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .paragraph {
    margin-bottom: 10px;
    text-align: justify;
    line-height: 1.5;

  }
  .bulletList {
    list-style-type: circle;
    color: black;
    padding-left: 20px; /* Ajusta según sea necesario */
  }
  