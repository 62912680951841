.movingFormPageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.movingImageContainer,
.movingFormContainer {
  flex: 1;
}

.form h2 {
  color: #428c9f;
  padding-left: 10px;
}

.formImage {
  border-radius: 15px;
  border: 1px solid #ccc;
  padding: 10px;
}

.loginImage {
  max-width: 100%;
  max-height: 70%;
  object-fit: cover;
  border-radius: 15%;
  margin: 20px;
  background-color: white;
}

.movingFormContainer {
  border-radius: 15px;
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dropdownContainer label {
  font-weight: bold;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.tofromrow {
  justify-content: space-between;
  gap: 16px;
}

.dropdown {
  flex: 1;
  border-radius: 15px;
  margin-right: 10px;
}

.selected {
  width: 100%;
}

.dateContainer,
.informationContainer,
.movingSelectorsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.checkboxContainer {
  flex-direction: row;
}

.dateContainer label {
  font-weight: bold;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.moving__input[type="date"],
select {
  padding: 8px;
  margin: 10px;
  border-radius: 15px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
}

.moving__input[type="checkbox"] {
  padding: 8px;
  margin: 10px;
  border: 1px solid #ccc;
}

.nextStepButton,
.buttonGoBack {
  width: 50%;
  padding: 10px 20px;
  font-size: 1.2em;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nextStepButton {
  background-color: #428c9f;
  color: white;
  border: none;
}

.buttonGoBack {
  background-color: #fff;
  color: #428c9f;
  border: 1px solid #428c9f;
}

.icon,
.errorIcon {
  margin-right: 8px;
}

.errorIcon {
  margin-right: 8px;
  color: red;
}

.errorContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.error {
  font-size: 12px;
  color: red;
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .movingFormPageContainer {
    flex-direction: column;
  }

  .loginImage,
  .movingImageContainer,
  .movingFormContainer {
    width: 100%;
    margin: 0 auto;
  }

  .dropdownContainer {
    flex-direction: column;
  }
}

@media (max-width: 950px) {
  .loginImage {
    max-width: 90%;
  }
}
