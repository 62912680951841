/* Impressum.module.css */
.containerImpressum {
  padding: 20px;
}

.titleImpressum {
  color: #428c9f;
  margin-bottom: 20px;
}

.contentImpressum {
  line-height: 1.6;
}

/* Puedes ajustar estos estilos según tus necesidades */
.mapContainer {
  width: 100%;
  height: 400px; /* Altura del mapa, ajusta según sea necesario */
  margin-top: 20px; /* Espacio entre el contenido y el mapa */
}

.mapImpressum {
  padding: 10px;
  width: 100%;
  height: 100%;
}
