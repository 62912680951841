/* src/styles/Company Dashboard styles/Companies Individuals Styles/UpgradeIndividuals.module.css */

.upgradeIndividualsContainer {
    padding: 20px;
    background-color: #ffffff; /* Fondo blanco para las tablas */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative; /* Contexto de posicionamiento para los elementos hijos */
}

.upgradeIndividualsHeading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #428c9f; /* Color del texto del encabezado */
}

/* Sección de Búsqueda */
.upgradeIndividualsSearchFilterContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.upgradeIndividualsSearchInput {
    width: 500px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    height: 40px;
    box-sizing: border-box;
}

/* Contenedor de Tablas */
.upgradeIndividualsTablesContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

/* Estilos de Tabla Individual */
.upgradeIndividualsTableWrapper {
    overflow-x: auto; /* Permitir desplazamiento horizontal */
    overflow-y: visible; /* Asegurar que el overflow vertical sea visible */
}

.upgradeIndividualsTableTitle {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #428c9f;
    text-align: center;
}

.upgradeIndividualsIndividualTable {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px; /* Ajustar según sea necesario */
    table-layout: auto; /* Mantener anchos de columnas fijos */
}

.upgradeIndividualsTableHeader {
    background-color: #428c9f; /* Color personalizado */
    color: white;
    padding: 12px;
    text-align: left;
    font-size: 1rem;
}

.upgradeIndividualsTableRow {
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s;
}

.upgradeIndividualsTableRow:hover {
    background-color: #f1f1f1;
}

.upgradeIndividualsTableCell {
    padding: 12px;
    /* Evitar el salto de línea */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    vertical-align: middle;
}

.upgradeIndividualsUpgradeButton {
    padding: 8px 12px;
    background-color: #428c9f;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s;
}

.upgradeIndividualsUpgradeButton:hover {
    background-color: #306b7a;
}

.upgradeIndividualsNoData {
    text-align: center;
    padding: 20px;
    color: #777;
}

.upgradeIndividualsError {
    color: red;
    text-align: center;
    margin-bottom: 20px;
}

.upgradeIndividualsLoading {
    text-align: center;
    color: #428c9f;
}

/* Modal Styles */
.upgradeIndividualsModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000; /* Asegurar que esté por encima de los dropdowns */
}

.upgradeIndividualsModalContent {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.upgradeIndividualsModalTitle {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #428c9f;
}

.upgradeIndividualsModalMessage {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #333333;
}

.upgradeIndividualsModalWarning {
    font-size: 0.9rem;
    margin-bottom: 20px;
    color: #ff4d4f;
}

.upgradeIndividualsModalButtons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.upgradeIndividualsConfirmButton {
    padding: 10px 20px;
    background-color: #428c9f; /* Color específico */
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.upgradeIndividualsConfirmButton:hover {
    background-color: #306b7a; /* Sombra más oscura al pasar el mouse */
}

.upgradeIndividualsCancelButton {
    padding: 10px 20px;
    background-color: #fdfdfd; /* Fondo claro */
    color: #428c9f;
    border: 2px solid #428c9f;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.upgradeIndividualsCancelButton:hover {
    background-color: #e6e6e6; /* Un poco más oscuro al pasar el mouse */
}

/* Dropdown Styles */
.upgradeIndividualsDropdown {
    position: relative;
    display: inline-block;
}

.upgradeIndividualsDropdownButton {
    padding: 8px 12px;
    background-color: #428c9f;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s;
}

.upgradeIndividualsDropdownButton:hover {
    background-color: #306b7a;
}

.upgradeIndividualsDropdownContent {
    display: none;
    background-color: #f9f9f9; /* Color de fondo para visibilidad */
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 2000; /* Asegurar que el dropdown esté por encima de otros elementos */
    border-radius: 4px;
}

.upgradeIndividualsDropdownContentButton {
    display: flex;
    width: 100%;
    padding: 12px 16px;
    text-align: left;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
}

.upgradeIndividualsDropdownContentButton:hover {
    background-color: #f1f1f1;
}

.upgradeIndividualsShow {
    display: block;
}
