.contactPersonRelotuContainer {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
  }
  
  .contactPersonRelotuImageContainer {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #428c9f;
  }
  
  .contactPersonRelotuImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .contactPersonRelotuBasicInfo {
    text-align: center;
  }
  
  .contactPersonRelotuName {
    font-size: 1.8em;
    color: #428c9f;
  }
  
  .contactPersonRelotuPosition {
    color: #555;
    font-size: 1.1em;
  }
  
  .contactPersonRelotuDescription {
    font-size: 1em;
    color: #333;
    text-align: center;
    line-height: 1.6;
  }
  
  .contactPersonRelotuIcon {
    color: #428c9f;
    margin-right: 8px;
  }
  
  .contactPersonRelotuDetails {
    text-align: center;
  }
  
  .contactPersonRelotuDetail {
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .contactPersonRelotuLink {
    color: #428c9f;
    text-decoration: none;
  }
  
  .contactPersonRelotuLink:hover {
    text-decoration: underline;
  }
  
  .contactPersonRelotuActions {
    display: flex;
    gap: 15px;
  }
  
  .contactPersonRelotuEmailButton,
  .contactPersonRelotuCallButton {
    background-color: #428c9f;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .contactPersonRelotuEmailButton:hover,
  .contactPersonRelotuCallButton:hover {
    background-color: #32718b;
  }
  
  .contactPersonRelotuAvailability {
    font-size: 1em;
    color: #777;
    text-align: center;
  }
  
  .contactPersonRelotuAdditional {
    text-align: center;
    margin-top: 15px;
  }
  
  .contactPersonRelotuAdditionalText {
    color: #333;
    font-size: 1em;
  }
  
  .contactPersonRelotuScheduleButton {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #428c9f;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .contactPersonRelotuScheduleButton:hover {
    background-color: #32718b;
  }
  
  /* Responsividad */
  @media (max-width: 768px) {
    .contactPersonRelotuContainer {
      max-width: 90%;
      padding: 20px;
    }
  
    .contactPersonRelotuDescription {
      font-size: 0.9em;
    }
  
    .contactPersonRelotuActions {
      flex-direction: column;
      width: 100%;
    }
  
    .contactPersonRelotuEmailButton,
    .contactPersonRelotuCallButton {
      width: 100%;
      padding: 12px;
    }
  }
  