/* src/styles/Company Dashboard styles/Companies Individuals Styles/MyIndividualsDashboard.module.css */

.myIndividualsDashboardContainer {
    padding: 20px;
    background-color: #f9f9f9; /* Light background color */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .myIndividualsHeading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333; /* Header text color */
  }
  
  /* Tab Navigation */
  .myIndividualsTabs {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    gap: 10px;
    border-bottom: 2px solid #ccc;
  }
  
  .myIndividualsTabButton {
    padding: 10px 20px;
    border: 1px solid #428c9f;
    background-color: #fff;
    color: #428c9f;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .myIndividualsTabButton:hover {
    background-color: #428c9f;
    color: #fff;
  }
  
  .myIndividualsActiveTab {
    background-color: #428c9f;
    color: #fff;
  }
  
  /* Tab Content */
  .myIndividualsTabContent {
    /* Additional styles if needed */
  }

  
  