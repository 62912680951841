.mainPage {
  display: flex;
  overflow: hidden;
}

.section {
  width: 50%;
  text-align: left;
  transition: all 0.15s ease;
}

.leftSection {
  background-color: #ccf7f4;
}

.rightSection {
  background-color: #edf3f4;
}

.leftSection:hover {
  width: 80%; /* Ancho ampliado al hacer hover directo sobre la sección izquierda */
  background-color: #80ebe4;
}

.rightSection:hover {
  width: 80%; /* Ancho reducido al hacer hover directo sobre la sección derecha */
  background-color: #d5dbdc;
}

.contentLeft,
.contentRight {
  margin: 20px;
  padding: 20px;
  margin-left: 20%;
  position: relative;
}

.contentLeft h1,
.contentRight h1 {
  text-align: center;
  color: black;
  margin-bottom: 30px;
  font-size: 50px;
}
.contentLeft h2 {
  text-align: center;
  color: #428c9f;
  margin-bottom: 30px;
  font-size: 20px;
}
.contentRight h2 {
  text-align: center;
  color: #5f6161;
  margin-bottom: 30px;
  font-size: 20px;
}
.contentLeft p,
.contentRight p {
  text-align: center;
  margin-bottom: 30px;
  font-size: 25px;
}

.contentImage {
  height: 500px;
  margin-left: 15%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.hoveredImage {
  width: 100px;
  height: auto;
}

.mainPageButton {
  background-color: black;
  border-radius: 15px;
  padding: 10px 20px;
  color: white;
  width: auto;
  height: auto;
  cursor: pointer;
  font-size: 1.2em;
}
.centeredButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 1250px) {
  .mainPage {
    flex-direction: column; /* Cambia a un diseño de columna cuando la pantalla es menor a 1250px */
  }

  .section {
    width: 100%; /* Ocupa todo el ancho cuando se cambia a una disposición de columna */
  }

  .leftSection:hover,
  .rightSection:hover {
    width: 100%; /* Ancho completo al hacer hover en dispositivos móviles */
  }

  .contentLeft,
  .contentRight {
    margin: 20px;
    padding: 20px;
  }

  .contentImage {
    margin-left: 15%;
  }

  .centeredButtonContainer {
    margin-top: 20px;
    text-align: center; /* Centra el botón en dispositivos móviles */
  }
}
