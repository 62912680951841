/* src/components/Settings/Settings.module.css */

.settingsContainer {
  color: #333;
  padding: 20px;
  max-width: 900px; /* Increased for more space */
  margin: 0 auto;
  background-color: #ffffff; /* Cleaner white background */
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 25px; /* Greater separation between sections */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  border-radius: 10px; /* Rounded borders */
}

.settingsHeading {
  font-size: 2em;
  color: #428c9f;
  text-align: center;
  margin-bottom: 20px;
}

/* Accordion */
.settingsAccordion {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f9f9f9;
}

.settingsAccordionButton {
  width: 100%;
  padding: 15px 20px;
  text-align: left;
  background-color: #428c9f;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1em;
  transition: background-color 0.3s;
}

.settingsAccordionButton:hover,
.settingsAccordionButton:focus {
  background-color: #32718b;
}

.settingsAccordionContent {
  padding: 20px;
  background-color: #ffffff;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Forms */
.settingsFormGroup {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.settingsFormGroup label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
}

.settingsInput,
.settingsSelect {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1em;
  transition: border-color 0.3s;
}

.settingsInput:focus,
.settingsSelect:focus {
  border-color: #428c9f;
  outline: none;
}

.settingsCheckboxGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.settingsCheckboxGroup label {
  font-weight: normal;
  color: #333;
}

.settingsToggleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Toggle Switch */
.settingsSwitch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.settingsSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.settingsSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.settingsSlider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.settingsSwitch input:checked + .settingsSlider {
  background-color: #428c9f;
}

.settingsSwitch input:checked + .settingsSlider::before {
  transform: translateX(26px);
}

/* Save Button (now removed, but keeping styles if needed) */
.settingsSaveButton {
  padding: 12px 20px;
  background-color: #428c9f; /* Custom blue */
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.settingsSaveButton:hover {
  background-color: #32718b;
}

/* Responsiveness */
@media (max-width: 768px) {
  .settingsContainer {
    padding: 15px;
  }

  .settingsAccordionButton {
    font-size: 1em;
    padding: 12px 15px;
  }

  .settingsFormGroup {
    margin-bottom: 15px;
  }

  .settingsSaveButton {
    width: 100%;
    padding: 10px 15px;
    font-size: 0.9em;
  }

  /* Center the tabs in small screens */
  .settingsAccordionContent form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
