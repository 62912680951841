/* src/styles/Company Dashboard styles/Companies Individuals Styles/ViewIndividuals.module.css */

.viewIndividualsContainer {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.viewIndividualsHeading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

/* Search and Filter Section */
.viewIndividualsSearchFilterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.viewIndividualsFilters {
  display: flex;
  gap: 15px;
  
}

.viewIndividualsSearchInput {
  padding: 10px 15px;
  border: 1px solid #ccc;
  font-size: 1rem;
  height: 40px;
  box-sizing: border-box;
  color: #999999;
  width: auto;
  border-radius: 4px;

}

.viewIndividualsSearchInput::placeholder {
  color: #999999;
  opacity: 1;
}

.viewIndividualsSelect {
  padding: 10px 15px;
  border: 1px solid #ccc;
  font-size: 1rem;
  height: 40px;
  box-sizing: border-box;
  appearance: none;
  background-color: #fff;
  border-radius: 4px;
  margin: 0;
  color: #999999;
}

/* Enfocar select */
.viewIndividualsSelect:focus {
  border-color: #428c9f;
  outline: none;
}

/* Table Styles */
.viewIndividualsTableWrapper {
  overflow-x: auto; /* Permitir desplazamiento horizontal */
}

.viewIndividualsIndividualTable {
  width: 100%;
  border-collapse: collapse;
  /* table-layout: fixed; */ /* Comentado para permitir ajuste automático */
}

/* Encabezados de la tabla */
.viewIndividualsTableHeader {
  background-color: #428c9f;
  color: white;
  padding: 12px;
  text-align: left;
  font-size: 1rem;
  /* Ajuste para permitir que el contenido del encabezado se expanda */
  min-width: 150px;
}

/* Encabezado específico para email */
.viewIndividualsEmailHeader {
  /* width: 250px; */ /* Eliminado para permitir ajuste automático */
}

/* Filas de la tabla */
.viewIndividualsTableRow {
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
}

.viewIndividualsTableRow:hover {
  background-color: #f1f1f1;
}

/* Celdas de la tabla */
.viewIndividualsTableCell {
  padding: 12px;
  white-space: normal; /* Permite que el texto se envuelva */
  word-wrap: break-word; /* Permite la ruptura de palabras largas */
  text-align: left;
  vertical-align: middle;
}

/* Celda específica para email */
.viewIndividualsEmailCell {
  /* width: 250px; */ /* Eliminado para permitir ajuste automático */
}

/* Estilos para estados activos e inactivos */
.viewIndividualsStatusActive {
  color: green;
  font-weight: bold;
}

.viewIndividualsStatusInactive {
  color: red;
  font-weight: bold;
}

/* Estilos para mensajes de no datos y errores */
.viewIndividualsNoData {
  text-align: center;
  padding: 20px;
  color: #777;
}

.viewIndividualsError {
  color: red;
  text-align: center;
  margin-bottom: 20px;
}

.viewIndividualsLoading {
  text-align: center;
  color: #428c9f;
}

/* Botón de Expansión de "Other Info" */
.viewIndividualsExpandButton {
  background-color: #428c9f; /* Fondo azul */
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

/* Hover para el botón de expansión */
.viewIndividualsExpandButton:hover {
  background-color: #306b7a; /* Color al pasar el cursor */
}

/* Contenedor del encabezado de "Other Info" */
.viewIndividualsTableHeaderOtherInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Asegurar que el botón no se sobreponga */
.viewIndividualsTableHeaderOtherInfo button {
  flex-shrink: 0;
  color: #428c9f;
  background-color: white;
  border-radius: 4px;
  font-size: 1.1rem;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .viewIndividualsSearchFilterContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .viewIndividualsFilters {
    flex-direction: column;
    gap: 10px; /* Reducir el espacio entre filtros en pantallas pequeñas */
  }

  .viewIndividualsSearchInput,
  .viewIndividualsSelect {
    width: 100%;
    flex: 1 1 auto;
  }

  .viewIndividualsExpandButton {
    padding: 5px;
    font-size: 0.9rem;
    margin-left: 5px;
  }

  .viewIndividualsTableHeaderOtherInfo {
    flex-direction: column;
    align-items: flex-start;
  }

  .viewIndividualsTableHeaderOtherInfo button {
    margin-left: 0;
    margin-top: 5px;
  }
}

@media (max-width: 480px) {
  .viewIndividualsHeading {
    font-size: 1.5rem;
  }

  .viewIndividualsTableHeader {
    padding: 8px;
    font-size: 0.9rem;
  }

  .viewIndividualsTableCell {
    padding: 8px;
    font-size: 0.9rem;
  }

  .viewIndividualsExpandButton {
    font-size: 0.8rem;
    padding: 4px 8px;
  }
}
