.chatContainer {
  max-width: 800px;
  width: 100%;
  height: 85vh;
  margin: 20px auto;
  padding: 20px;
  /* background-color: #f9f9f9;*/
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.headingContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}

/* Añade estos estilos */

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.logoAvatarContainer {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  width: auto;
}

.separator {
  width: 1px;
  height: 60px; /* Ajusta la altura según sea necesario */
  background-color: #428c9f;
  margin: 0 15px;
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.avatarBox {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #428c9f;
}

.userName {
  margin-left: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.heading {
  margin-left: 10px;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #428c9f;
}

.messagesContainer {
  height: 20rem;
  overflow-y: auto;
  /*background-color: #eee;*/
  padding: 0.5rem;
}

.messageHeading {
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
}

.formContainer {
  margin-top: 0.5rem;
}

.textArea {
  margin-top: 0.5rem;
  width: 100%;
  background-color: #f1f5f9;
  padding: 0.5rem;
  border-radius: 0.375rem;
  color: #428c9f;
}

.button {
  margin-top: 0.5rem;
  background-color: white;
  border: 1px solid #428c9f;
  border-radius: 15px;
  color: #428c9f;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
}

.buttonIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}

.roundedImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.horizontalRule {
  border-top-width: 2px;
  border-color: #428c9f;
  margin-bottom: 1.5rem;
}

.inputForm {
  display: flex;
  width: 100%;
  padding: 10px;
}

.input {
  color: #000;
  flex-grow: 1;
  padding: 16px 24px;
  margin-right: 10px;
  border-radius: 60px;
  border: 2px solid transparent;
  font-size: 1em;
  background-color: #eee;
}

.input:focus {
  outline: none;
  border-color: #428c9f;
}

.messagesContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: visible;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  white-space: normal;
}

.userMessage,
.assistantMessage,
.codeMessage {
  margin: 8px 0;
  padding: 8px 16px;
  align-self: flex-start;
  border-radius: 15px;
  max-width: 80%;
  overflow-wrap: break-word;
  text-align: left;
}

.userMessage {
  align-self: flex-end;
  color: #000;
  background-color: #ddd;
}

.assistantMessage {
  background-color: #fdfdfd;
  color: #000;
  border: 1px solid #428c9f;
}

.codeMessage {
  padding: 10px 16px;
  background-color: #fdfdfd;
  font-family: monospace;
}

.introContainer {
  text-align: center;
  margin-bottom: 20px;
  border: 1px solid #428c9f;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
}

.introTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #428c9f;
}

.introText {
  font-size: 1rem;
  color: black;
}

.predefinedQuestions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.questionBox {
  border: 1px solid #428c9f;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  color: #000;
  text-align: center;
  transition: background-color 0.3s;
}

.questionBox:hover {
  background-color: #ddd;
}

.plusSign {
  margin-right: 8px;
  font-weight: bold;
}

.assistantMessage {
  background-color: #fdfdfd;
  color: #000;
  border: 1px solid #428c9f;
  border-radius: 10px;
  padding: 16px;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 80%;
  overflow-wrap: break-word;
}

.assistantMessage p {
  margin: 0;
}

.assistantMessage pre {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  overflow-x: auto;
  font-family: monospace;
  font-size: 0.9rem;
}

.assistantMessage code {
  background-color: #eee;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.9rem;
}

.assistantMessage h1,
.assistantMessage h2,
.assistantMessage h3 {
  margin: 10px 0;
  font-weight: bold;
  color: #333;
}

.assistantMessage ul {
  list-style-type: disc;
  margin-left: 20px;
  padding-left: 20px;
}

.assistantMessage ol {
  list-style-type: decimal;
  margin-left: 20px;
  padding-left: 20px;
}

.assistantMessage a {
  color: #428c9f;
  text-decoration: underline;
}

.assistantMessage blockquote {
  padding: 10px;
  margin: 10px 0;
  border-left: 5px solid #428c9f;
  background-color: #f9f9f9;
  font-style: italic;
}

.assistantLoading {
  display: flex;
  align-items: center;
}

.assistantLoading::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: #555;
  animation: blink 1s infinite steps(2, start);
}

.assistantLoadingText {
  color: #555;
  font-style: italic;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@media (max-width: 700px) {
  .chatContainer {
    max-width: 90%;
    padding: 15px;
    height: 90vh;
  }
  .inputForm {
    flex-direction: column;
  }

  .messagesContainer {
    height: 0;
  }

  .input {
    margin-bottom: 10px;
    align-self: left;
    width: auto;
  }

  .button {
    width: auto;
    margin-left: 0;
    align-items: center;
    align-self: left;
  }

  .predefinedQuestions {
    grid-template-columns: 1fr;
  }
}
