/* CookiesSettings.module.css */
.containerCookies {
  margin: 20px;
  line-height: 1.6;
  max-width: min(1400px,90%);
  margin: auto;
}

.headerCookies {
  color: #428c9f;
  margin-top: 20px;
  margin-bottom: 10px;
}

.sectionCookies {
  margin-bottom: 30px;
}

.subHeaderCookies {
  font-size: 1.5em;
  color: #428c9f;
  margin-bottom: 15px;
}

.paragraphCookies {
  font-size: 1.2em;
  margin-bottom: 10px;
  text-align: justify;
  line-height: 1.5;
}

.cookiesbuttonGroup {
  display: flex;
  gap: 10px;
}

.cookieHandlerButton {
  /* Estilos generales para el botón */
}
