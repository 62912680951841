/* src/styles/Calculator styles/CostCalculator.module.css */

/* Estilos generales */
.costCalculatorGross__container {
  padding: 20px;
  padding-top: 0px;
  margin: auto;
  text-align: left;
  color: #333;
  background: linear-gradient(to bottom, #428c9f, #ffffff);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 30px;
}

.costCalculatorGross__instructions {
  flex: 1;
  padding: 20px;
  margin: 20px auto;
  text-align: left;
  color: #333;
  background: linear-gradient(to bottom, #e6f7f9, #ffffff);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.costCalculatorGross__instructions h3 {
  color: #428c9f;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.costCalculatorGross__instructions ul {
  list-style: disc;
  padding-left: 20px;
}

.costCalculatorGross__instructions li {
  margin-bottom: 10px;
}

.costCalculatorGross__calculatorAndButton {
  flex: 3;
}
.costCalculatorGross__calculator {
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  text-align: left;
  color: #333;
  background: linear-gradient(to bottom, #e6f7f9, #ffffff);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* Párrafo adicional */
.costCalculatorGross__instructions p {
  /*font-size: 1.1rem;*/
  /*color: #555;*/
  /*line-height: 1.8;*/
  /*font-style: italic;*/
  /*text-align: center;*/
  /*background-color: #f4f4f4;*/
  /*border-radius: 8px;*/
  margin: 10px;
  /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* Título principal */
.costCalculatorGross__title {
  color: #428c9f;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 1px;
}

/* Secciones de botones y formularios */
.costCalculatorGross__buttonSection {
  border: 2px solid transparent;
  border-radius: 10px;
  transition: border-color 0.3s, background-color 0.3s;
}

.costCalculatorGross__form {
  flex: 1 1 400px;
  border: 2px solid transparent;
  border-radius: 10px;
  padding: 20px;
  transition: border-color 0.3s, background-color 0.3s;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
}

/* Grupos de inputs */
.costCalculatorGross__inputGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background: #f8f8f8;
}

.costCalculatorGross__inputGroup:hover {
  border-color: #428c9f;
}

/* Etiquetas */
.costCalculatorGross__label {
  font-size: 0.9rem;
  font-weight: bold;
  width: 30%;
  margin-right: 10px;
  align-self: center;
}

/* Contenedor de inputs */
.costCalculatorGross__inputWrapper {
  flex: 1;
  display: flex;
  align-items: center;
}

/* Iconos */
.costCalculatorGross__icon {
  font-size: 1.5rem;
  color: #428c9f;
  margin-right: 10px;
  text-align: center;
}

/* Selectores */
.costCalculatorGross__select {
  width: 160px;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff;
  transition: border-color 0.3s ease;
  margin-left: 10px;
}

.costCalculatorGross__select:hover,
.costCalculatorGross__select:focus {
  border-color: #428c9f;
  outline: none;
}

/* Botones */
.costCalculatorGross__button {
  background-color: #428c9f;
  color: white;
  border: none;
  padding: 12px 25px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
  display: block;
  margin: auto;
  transition: background-color 0.3s, transform 0.3s;
}

.costCalculatorGross__button:hover {
  background-color: #306b7a;
  transform: translateY(-3px);
}

.costCalculatorGross__button:active {
  transform: scale(0.98);
}

/* Inputs */
.costCalculatorGross__input {
  padding: 10px;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 160px;
}

.costCalculatorGross__input:focus {
  border-color: #428c9f;
  box-shadow: 0 0 8px rgba(66, 140, 159, 0.5);
  outline: none;
}

.costCalculatorGross__input[disabled] {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.costCalculatorGross__input::placeholder {
  color: #999;
  font-style: italic;
}

/* Mensajes de error */
.costCalculatorGross__errorContainer {
  margin-bottom: 20px;
  text-align: center;
  display: none; /* Oculto por defecto */
}

.costCalculatorGross__errorContainer.showError {
  display: block;
}

.costCalculatorGross__errorMessage {
  color: #ff4d4f;
  font-size: 1rem;
  font-weight: bold;
  background-color: #ffe6e6;
  padding: 10px;
  border: 1px solid #ff4d4f;
  border-radius: 5px;
}

/* Toggle personalizado */
.costCalculatorGross__toggleWrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.costCalculatorGross__toggle {
  appearance: none;
  width: 160px;
  height: 40px;
  background-color: #60919d;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
  border: none;
}

.costCalculatorGross__toggle:checked {
  background-color: #428c9f;
}

.costCalculatorGross__toggle::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 60px;
  height: 32px;
  border-radius: 16px;
  background-color: white;
  transition: transform 0.3s ease;
}

.costCalculatorGross__toggle:checked::before {
  transform: translateX(90px);
}

.costCalculatorGross__toggleTextWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  pointer-events: none;
}

.costCalculatorGross__toggleTextLeft,
.costCalculatorGross__toggleTextRight {
  font-size: 0.9rem;
  font-weight: bold;
  color: white;
  transition: color 0.3s ease;
}

.costCalculatorGross__toggle:not(:checked)
  .costCalculatorGross__toggleTextLeft {
  color: black;
}

.costCalculatorGross__toggle:checked
  ~ .costCalculatorGross__toggleTextWrapper
  .costCalculatorGross__toggleTextRight {
  color: white;
}

/* Responsividad */
@media (max-width: 768px) {
  .costCalculatorGross__form {
    grid-template-columns: 1fr;
  }

  .costCalculatorGross__button {
    font-size: 16px;
    padding: 10px 20px;
  }

  .costCalculatorGross__container {
    flex-direction: column; /* Cambia la dirección a columna */
    gap: 20px; /* Ajusta el espacio entre los elementos */
  }

  .costCalculatorGross__button {
    font-size: 16px;
    padding: 10px 20px;
  }

  .costCalculatorGross__inputGroup {
    width: 90%;
    margin-bottom: 0px;
  }
  .costCalculatorGross__label {
    font-size: 0.8rem;
    margin-right: 15px;
  }

  .costCalculatorGross__input {
    margin-left: 10px;
    width: 140px;

  }
  .costCalculatorGross__select {
    margin-left: 20px;
  }

  .costCalculatorGross__calculator {
    padding: 0px;
  }
}




.costCalculatorGross__buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  position: relative;
}


.costCalculatorGross__button:hover {
  background-color: #367a89;
}

.costCalculatorGross__loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px; 
}

.costCalculatorGross__spinner {
  border: 4px solid #f3f3f3; /* Color gris claro */
  border-top: 4px solid #428c9f; /* Color principal */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.8s linear infinite;
}

/* Animación del spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
