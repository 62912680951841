.textImageContainer {
  margin-bottom: 20px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  justify-content: space-between;
  border-radius: 5%;
}

.textSection {
  align-items: flex-end;
  align-items: center;
  flex: 1;
  padding-right: 20px;
  margin-left: 10%;
  text-align: left;
}

.textSection h1 {
  font-size: 36px;
  margin: 20px 0px;
}

.textSection p {
  font-size: 20px;
  color: black;
  margin-right: 20px;
}

.imageSection {
  margin: 20px;
  flex: 1;
  padding: 20px;
}

.imageSection img {
  margin-left: 0px;
  max-width: 70%;
  height: auto;
  border-radius: 5%;
}

.buttonTextImageSection {
  margin-top: 40px;
  display: flex;
  gap: 20px;
}

.buttonTextImage {
  background-color: #428c9f;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 24px;
  border-radius: 15px;
}

.registerButton {
  background-color: #428c9f;
  border: 0.5px solid #2f6873;
  box-shadow: 0 4px 8px rgba(80, 166, 181, 0.4);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.registerButton:hover {
  background-color: #2f6873;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(80, 166, 181, 0.6);
}

.iconList {
  list-style: none;
  padding: 0;
}

.iconList li {
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
  color: #428c9f;
}

.freeBadge {
  position: absolute;
  background-color: #2f6873;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  gap: 2px;
}

.freeBadge .faGift {
  margin-right: 5px;
  font-size: 14px;
}

@media (max-width: 1130px) {
  .textImageContainer {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
  
  .textSection {
    margin-left: 0px;
    max-width: 90%;
    padding-right: 0;
    text-align: left;
  }
  
  .textSection p {
    font-size: 20px;
    color: black;
    margin-right: 20px;
  }
  
  .imageSection img {
    max-width: 100%;
  }
  
  .buttonTextImageSection {
    justify-content: center;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
