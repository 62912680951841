/* FAQ.module.css */
.faqContainer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.faqItem {
  flex: 1 1 calc(50% - 10px);
  border: 1px solid #428c9f;
  border-radius: 10px;
  margin: 5px 0;
  padding: 15px;
  transition: background-color 0.3s ease;
}

.faqItem button {
  background: none;
  border: none;
  border-color: #428c9f;
  text-align: left;
  width: 100%;
  font-size: 18px;
  cursor: pointer;
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  color: black;
}

.faqItem button:focus {
  outline: none;
}

.faqItem button .faIcon {
  color: #428c9f;
}

.faqItem p {
  margin-left: 22px;
  margin-right: 12px;
  color: grey;
  margin-top: 5px;
  font-size: 16px;
  text-align: left;
}

.faqItem:hover {
  background-color: #e4edf2;
}

@media (max-width: 550px) {
  .faqContainer {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
