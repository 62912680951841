/* TasksCompanies.module.css */

/* Container styles */
.container {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    line-height: 1.6;
  }
  
  /* Sidebar styles */
  .sidebar {
    width: 250px;
    position: sticky;
    top: 20px;
    align-self: flex-start;
    overflow-y: auto;
    flex-shrink: 0;
    border: 1px solid #e0e0e0; /* Borde más suave */
    border-radius: 0 0 8px 8px; /* Border-radius solo en las esquinas inferiores */
    padding: 10px;
    background-color: #fff;
  }
  
  /* Sidebar title */
  .sidebarTitle {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #428c9f;
  }
  
  /* Move type header */
  .moveTypeHeader {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .moveTypeToggleIcon {
    font-size: 1.2em;
  }
  
  /* Individuals list under each move type */
  .individualsList {
    margin-left: 10px;
  }
  
  /* Grouping by moveType */
  .moveTypeGroup {
    margin-bottom: 20px;
  }
  
  /* Individual cards */
  .individualCard {
    background-color: #fff;
    border: 1px solid #e0e0e0; /* Borde más suave */
    margin-bottom: 15px;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 0 0 8px 8px; /* Border-radius solo en las esquinas inferiores */
  }
  
  .individualCard:hover {
    background-color: #f0f0f0;
  }
  
  .selectedCard {
    border-color: #007bff;
    background-color: #e6f0ff;
  }
  
  .cardFront,
  .cardBack {
    width: 100%;
  }
  
  .cardBack {
    margin-top: 10px;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 0 0 8px 8px; /* Border-radius en las esquinas inferiores */
  }
  
  /* No individuals message */
  .noIndividualsMessage {
    font-size: 1em;
    color: #666;
    margin-top: 20px;
  }
  
  /* Main content styles */
  .mainContent {
    flex: 1;
    min-width: 0;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 0 0 8px 8px; /* Border-radius solo en las esquinas inferiores */
    border: 1px solid #e0e0e0; /* Borde más suave */
    background-color: #fff;
  }
  
  .title {
    font-size: 2em;
    color: #428c9f;
    margin-bottom: 10px;
  }
  
  .subtitle {
    margin-bottom: 20px;
  }
  
  .toggleButtonsContainer {
    display: flex;
    justify-content: flex-end;
    align-self: center;
  }
  
  .toggleButton {
    display: flex;
    align-items: center;
    margin-left: 16px;
    padding: 12px;
    cursor: pointer;
    border: none;
    background: transparent;
    color: #333;
    font-size: 16px;
    transition: color 0.3s ease-in-out;
  }
  
  .toggleIcon {
    margin-right: 8px;
  }
  
  .iconPhone {
    margin-right: 8px;
  }
  
  .toggleOn {
    color: #28a745;
  }
  
  .toggleOff {
    color: orange;
  }
  
  /* Tasks list and groups */
  .tasksList {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .groupContainer {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #e0e0e0; /* Borde más suave */
    border-radius: 0 0 8px 8px; /* Border-radius solo en las esquinas inferiores */
  }
  
  .groupHeader {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    color: #428c9f;
  }
  
  .groupTasks {
    margin-top: 10px;
  }
  
  .groupIcon {
    color: white;
    background-color: #428c9f;
    padding: 3px;
  }
  
  .taskItem {
    padding: 10px;
    border: 1px solid #428c9f;
    border-radius: 0 0 8px 8px; /* Border-radius solo en las esquinas inferiores */
    transition: background-color 0.3s ease;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .taskItem:hover {
    background-color: #e4edf2;
  }
  
  .taskHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  
  .taskTitle {
    font-weight: bold;
    text-align: left;
  }
  
  .checkbox {
    margin-left: auto;
  }
  
  .taskDescription {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    text-align: left;
  }
  
  .taskDescription h3 {
    margin-top: 30px;
    color: #555;
  }
  
  .taskDescription p {
    color: #555;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .taskDescription ul {
    color: #555;
    line-height: 1.5;
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .taskDescription li {
    margin-bottom: 5px;
  }
  
  /* Details button container */
  .containerDetailsButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  
  .actionButton {
    font-size: 1.2em;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
    border-radius: 15px;
    background-color: #428c9f;
    color: white;
    cursor: pointer;
    border: none;
  }
  
  /* Placeholder styles */
  .placeholderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .placeholderImage {
    max-width: 50%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .placeholderText {
    font-size: 1.2em;
    color: #666;
    text-align: center;
    padding: 0 20px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1050px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  
    .sidebar {
      width: 100%;
      margin-right: 0;
      position: static;
    }
  
    .mainContent {
      width: 100%;
    }
  
    .content {
      width: 100%;
    }
  }
  