.contactImageContainer {
  margin-left: 0px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  justify-content: space-between;
}

.contactSection {
  align-items: flex-end;
  align-items: center;
  flex: 1;
  padding-right: 20px;
  margin-left: 10%;
  text-align: left;
}

.contactSection h1 {
  font-size: 36px;
  margin: 20px 0px;
}

.contactSection p {
  font-size: 20px;
  margin-right: 20px;
  margin-bottom: 60px;
}

.imageSection {
  margin: 20px;
  flex: 1;
  padding: 20px;
  margin-bottom: 0px;
}

.imageSection img {
  margin-left: 0px;
  max-width: 80%;
  width: auto;
  height: auto;
  border-radius: 5%;
}

.buttonGroupContactImage {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
}


.icon {
  margin-right: 10px;
  color: white;
  font-size: 24px;
}

.contactLink {
  display: inline-flex;
  align-items: center;
  color: #428c9f;
  text-decoration: none;
  font-size: 24px;
  gap: 8px;
}
.contactLink:hover {
  text-decoration: underline;
}

.buttonTextImage {
  background-color: #428c9f;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 24px;
  border-radius: 15px;
  width: 325px;
}

.buttonTextImage:hover {
  background-color: #2f6873;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(80, 166, 181, 0.6);
}

.registerButton {
  background-color: #428c9f;
  border: 0.5px solid #2f6873;
  box-shadow: 0 4px 8px rgba(80, 166, 181, 0.4);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.registerButton:hover {
  background-color: #2f6873;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(80, 166, 181, 0.6);
}

@media (max-width: 1130px) {
  .contactImageContainer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contactSection {
    margin-left: 0px;
    max-width: 90%;
    padding-right: 0;
    text-align: left;
  }

  .contactSection p {
    font-size: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
  }

  .imageSection {
    margin: 20px;
    flex: 1;
    padding: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .imageSection img {
    max-width: 100%;
  }

  .buttonGroupContactImage {
    align-items: center;
  }
  
}
