*{
    box-sizing: border-box;
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
    margin: 0;
    text-align: center;
    background-color: #ffffff;
    padding: 0;
    width: auto;
    height: auto;
    overflow: auto;
    position: inherit;

  }



.main-content {

  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}




  
