/* Footer.module.css */

.footerContainer {
  background-color: #428c9f;
  text-align: center;
  padding: 20px 0;
  margin-top: 30px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-top: 1px solid #eaeaea;
  color: white;
  width: 100%;
}

.footerContainer p {
  font-size: 20px;
  margin: 10px 0;
}

.footerSection {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 0;
}

.footerLogoSection {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footerLogo {
  width: 140px;
  height: 50px;
  margin: 20px;
  filter: brightness(0) invert(1);
}

.footerSectionList {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  color: white;
}

.footerListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerList {
  list-style: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}

.footerList h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: white;
}

.footerList li {
  margin-bottom: 5px;
}

.footerList a {
  color: white;
  text-decoration: none;
}

.footerList a:hover {
  color: #ccc;
}

/* Responsive Styles */
@media (max-width: 905px) {
  .footerSectionList {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footerLogoSection {
    flex-direction: column;
  }
}
